import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import * as yup from "yup";

import { getErrorLabels } from "helpers/errors";
import DealerQuestion from "components/form/DealerQuestion";

const DealerQuestions = (props) => {
  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.application?.lenderQuestions) {
      let lenderQuestions = props.application.lenderQuestions;
      if (typeof lenderQuestions === "string") {
        lenderQuestions = JSON.parse(lenderQuestions);
      }

      const questionAnswers = {};
      lenderQuestions.map((question) => {
        questionAnswers[question.questionGuid] = question.answerValue;
      });

      setAnswers(questionAnswers);
    } else {
      setAnswers({});
    }
  }, [props.application]);

  const getAnswer = (question) => {
    if (Object.keys(answers).includes(question.questionGuid)) {
      return answers[question.questionGuid];
    }

    return "";
  };

  const getInputFormat = (question) => {
    if (question?.answerType === "Number") {
      return /^[0-9]*$/;
    } else if (question?.answerType === "Decimal") {
      return /^[0-9]*(\.[0-9]*)?$/;
    }

    return null;
  };

  const handleSaveSection = () => {
    const questionSchema = {};
    props.questions.map((question) => {
      if (question.answerType === "Boolean") {
        questionSchema[question.questionGuid] = yup.boolean().required();
      } else {
        questionSchema[question.questionGuid] = yup.string().required();
      }
    });

    const validationSchema = yup.object().shape(questionSchema);

    validationSchema
      .validate(answers, { abortEarly: false })
      .then(() => {
        props.onUpdate({
          lenderQuestions: Object.keys(answers).map((questionGuid) => ({
            questionGuid: questionGuid,
            answerValue: answers[questionGuid],
          })),
        });
        props.changeTab("legals");
      })
      .catch((err) => {
        console.log(err);
        setErrors(getErrorLabels(err));
      });
  };

  return (
    <div className="customer-details">
      <Row>
        {props.questions.map((question) => (
          <Col xs={12} md={6} className="mb-5" key={question.questionGuid}>
            <DealerQuestion
              question={question}
              answers={answers}
              setAnswers={setAnswers}
              errors={errors}
            />
          </Col>
        ))}
      </Row>

      <Row className="mb-0">
        <Col xs={12} className="d-flex justify-content-end">
          <button
            className="dark focus"
            onClick={handleSaveSection}
            tabIndex={19}
          >
            Save customer & continue
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default DealerQuestions;
