import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import moment from "moment";

import {
  getApplicationCreateObject,
  getBlankApplication,
} from "helpers/application";
import {
  createApplication,
  getClientPreQualifiedQuotes,
  getCreateApplicationSchema,
  sendCustomerLegals,
  getDealerQuestions,
} from "services";
import { getErrorLabels, generateValidationSchema } from "helpers/errors";
import { setPageLoading } from "store/actions/auth";
import { setCurrentQuote } from "store/actions/lenders";
import ApplicationInformationForm from "components/form/application/ApplicationInformationForm";
import ErrorModal from "components/modals/ErrorModal";
import QuoteBuilder from "components/QuoteBuilder";
import WarningModal from "components/modals/WarningModal";

const CreateQuotePage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("customer");
  const [application, setApplication] = useState({});
  const [applicationSchema, setApplicationSchema] = useState({});
  const [applicationValidation, setApplicationValidation] = useState({});
  const [dealerQuestions, setDealerQuestions] = useState([]);
  const [errors, setErrors] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);

  const dealerGuid = useSelector((state) => state?.auth?.dealerGuid);

  useEffect(() => {
    getCreateApplicationSchema().then((res) => {
      setApplicationSchema(res);

      let validation = generateValidationSchema(res);
      setApplicationValidation(validation);
    });

    getDealerQuestions(dealerGuid)
      .then((res) => {
        setDealerQuestions(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleApplicationUpdate = (
    values,
    submitApplication = false,
    sendClientEmail = false
  ) => {
    let currentApplication = getBlankApplication(application);

    currentApplication = {
      ...currentApplication,
      ...values,
    };

    setApplication(currentApplication);

    if (sendClientEmail) {
      sendCustomerLegals({
        clientEmail: values.client.clientEmail,
        client: {
          firstName: values?.client?.firstName,
          lastName: values?.client?.lastName,
          title: values?.client?.title,
        },
        dealer: dealerGuid,
      }).then((res) => {
        setApplication({
          ...currentApplication,
          ...values,
          acceptTAndCDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          acceptPrivacyPolicy: moment().format("YYYY-MM-DD HH:mm:ss"),
          acceptInitialDisclosure: moment().format("YYYY-MM-DD HH:mm:ss"),
          acceptCommissionDisclosure: moment().format("YYYY-MM-DD HH:mm:ss"),
        });
      });
    }

    if (submitApplication) {
      let createApplicationObj = getApplicationCreateObject(
        currentApplication,
        dealerGuid
      );

      applicationValidation
        .validate(createApplicationObj, { abortEarly: false })
        .then((res) => {
          createApplication(createApplicationObj)
            .then((res) => {
              if (res.quote && Object.keys(res.quote).length > 0) {
                dispatch(setCurrentQuote(res.quote));
                dispatch(setPageLoading(false));
                navigate(`/customers/${res?.quote.applicationGuid}`);
              } else {
                setWarningMessage(
                  "Something went wrong. Please try again later."
                );
                dispatch(setPageLoading(false));
              }
            })
            .catch((err) => {
              setTimeout(() => {
                dispatch(setPageLoading(false));
                setWarningMessage(err.response?.data?.error);
              }, 1000);
              console.log("err", err);
            });
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(setPageLoading(false));
          setErrors(getErrorLabels(err));
          setShowErrorModal(true);
        });
    }
  };

  return (
    <div className="application-info-page">
      <div className="page-top">
        <h3 className="page-title">Add new quote</h3>
      </div>

      <Row className="mt-4">
        <Col xs={12} md={8}>
          <ApplicationInformationForm
            application={
              Object.keys(application).length > 0 ? application : null
            }
            schema={applicationSchema}
            onUpdate={handleApplicationUpdate}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            dealerQuestions={dealerQuestions}
            errors={errors}
          />
        </Col>
        <Col md={4}>
          <QuoteBuilder
            application={
              Object.keys(application).length > 0 ? application : null
            }
            onUpdate={handleApplicationUpdate}
            setActiveTab={setActiveTab}
          />
        </Col>
      </Row>

      <ErrorModal
        show={showErrorModal}
        onHide={(e) => setShowErrorModal(false)}
        errors={errors}
      />

      {warningMessage !== null && (
        <WarningModal
          show={warningMessage !== null}
          text={warningMessage}
          onCancel={(e) => setWarningMessage(null)}
        />
      )}
    </div>
  );
};

export default CreateQuotePage;
