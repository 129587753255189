import React, { useEffect, useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";

import { formatCurrency } from "helpers/currency";
import DealerCommissionModal from "components/modals/DealerCommissionModal";
import LenderLogo from "components/LenderLogo";

const FullQuoteStep = (props) => {
  const [showCommissionModal, setShowCommissionModal] = useState(false);
  const getTotalCredit = () => {
    let total = props?.application?.vehicle?.salesValue;

    let deposit =
      props.application?.deposit +
      (props.application?.pxDeposite ? props.application?.equity : 0);

    return total - deposit;
  };

  return (
    <>
      {props.showHeader && (
        <Modal.Header closeButton>
          Full finance quote
          {props.lender && <LenderLogo image={props.lender?.lenderLogo} />}
        </Modal.Header>
      )}

      <Modal.Body className="quote-modal">
        {props.quote && (
          <>
            <Row>
              <Col xs={6}>Lender</Col>
              <Col xs={6} className="quote-value">
                {props.quote?.lender?.lenderName}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>Finance Type</Col>
              <Col xs={6} className="quote-value">
                {props.quote?.productTypeDesc}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>Purchase price</Col>
              <Col xs={6} className="quote-value">
                £
                {props.application?.vehicle?.salesValue.toLocaleString("en-GB")}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>Cash deposit</Col>
              <Col xs={6} className="quote-value">
                £{props.application?.deposit?.toLocaleString("en-GB")}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>Part ex deposit</Col>
              <Col xs={6} className="quote-value">
                £
                {props.application?.pxDeposite
                  ? props.application?.equity.toLocaleString("en-GB")
                  : 0}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>First payment</Col>
              <Col xs={6} className="quote-value">
                £{formatCurrency(props.quote?.payments)}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>{props.application?.term} monthly payments</Col>
              <Col xs={6} className="quote-value">
                £{formatCurrency(props.quote?.payments)}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>Final payment</Col>
              <Col xs={6} className="quote-value">
                £{formatCurrency(props.quote?.residualValue)}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>Interest rate (APR)</Col>
              <Col xs={6} className="quote-value">
                {props.quote?.apr?.toFixed(1)}%
              </Col>
            </Row>
            <Row>
              <Col xs={6}>Amount of credit</Col>
              <Col xs={6} className="quote-value">
                £{formatCurrency(getTotalCredit())}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>Total charge for credit</Col>
              <Col xs={6} className="quote-value">
                £{formatCurrency(props.quote?.totalInterest)}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>Total amount payable</Col>
              <Col xs={6} className="quote-value">
                £{formatCurrency(props.quote?.totalPayable)}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>Option to purchase fee</Col>
              <Col xs={6} className="quote-value">
                £{formatCurrency(props?.quote?.optionToPurchaseFee)}
              </Col>
            </Row>
            <Row>
              <Col xs={6}>Dealer Commission</Col>
              <Col xs={6} className="quote-value">
                £{formatCurrency(props?.quote?.dealerCommission)}
                <a
                  href="#"
                  onClick={(e) => setShowCommissionModal(true)}
                  className="commission-info-link"
                >
                  Learn how this is calculated
                </a>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>

      {props.showFooter && (
        <Modal.Footer className="justify-content-between">
          <button onClick={props.onBack} className="outline">
            Go back
          </button>
          <button onClick={(e) => props.onProceed({})} className="dark">
            Submit to lender
          </button>
        </Modal.Footer>
      )}

      <DealerCommissionModal
        show={showCommissionModal}
        onHide={(e) => setShowCommissionModal(false)}
        quote={props?.quote}
        lender={props?.lender}
      />
    </>
  );
};

FullQuoteStep.defaultProps = {
  showHeader: true,
  showFooter: true,
};

export default FullQuoteStep;
