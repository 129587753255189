import React from "react";
import { Form } from "react-bootstrap";

import Error from "components/Error";
import TextInput from "components/form/TextInput";

const DealerQuestion = ({ question, answers, setAnswers, errors }) => {
  const getAnswer = (question) => {
    if (Object.keys(answers).includes(question.questionGuid)) {
      return answers[question.questionGuid];
    }

    return "";
  };

  const getInputFormat = (question) => {
    if (question?.answerType === "Number") {
      return /^[0-9]*$/;
    } else if (question?.answerType === "Decimal") {
      return /^[0-9]*(\.[0-9]*)?$/;
    }

    return null;
  };

  return (
    <>
      <label>{question.lenderSpecificQuestion}</label>

      <div>
        {question?.answerType === "List" ? (
          <>
            <Form.Select
              name={question.questionGuid}
              value={answers[question.questionGuid]}
              onChange={(e) =>
                setAnswers({
                  ...answers,
                  [question.questionGuid]: e.target.value,
                })
              }
            >
              <option value=""></option>
              {question.answerList?.map((option) => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </Form.Select>

            <Error name={question.questionGuid} errors={errors} />
          </>
        ) : question?.answerType === "Boolean" ? (
          <>
            <Form.Select
              name={question.questionGuid}
              value={answers[question.questionGuid]}
              onChange={(e) =>
                setAnswers({
                  ...answers,
                  [question.questionGuid]: e.target.value,
                })
              }
            >
              <option value=""></option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Form.Select>
            <Error name={question.questionGuid} errors={errors} />
          </>
        ) : (
          <>
            <TextInput
              name={question.questionGuid}
              value={getAnswer(question)}
              onChange={(e) =>
                setAnswers({
                  ...answers,
                  [question.questionGuid]: e.target.value,
                })
              }
              format={getInputFormat(question)}
              className="w-100"
            />
            <Error name={question.questionGuid} errors={errors} />
          </>
        )}
      </div>
    </>
  );
};

export default DealerQuestion;
