import React from "react";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import moment from "moment";

import { loginSuccessful, logout } from "store/actions/auth";
import { extendToken } from "services";

const IdleTimer = ({
  showExpiryModal,
  setShowExpiryModal,
  tokenExpiry,
  onExtendToken,
  onAutoLogout,
}) => {
  const dispatch = useDispatch();

  let activeInterval;
  let idleInterval;

  const onActive = () => {
    activeInterval = setInterval(() => {
      checkActiveSession();
    }, 30000);
  };

  const onIdle = () => {
    idleInterval = setInterval(() => {
      checkSessionExpiry();
    }, 30000);
  };

  const checkActiveSession = () => {
    if (isActive) {
      const expiresIn = moment(tokenExpiry).diff(moment(), "seconds");

      if (expiresIn < 120) {
        extendToken().then((res) => {
          dispatch(loginSuccessful(res));
          clearInterval(activeInterval);

          if (onExtendToken) {
            onExtendToken();
          }
        });
      }
    } else {
      clearInterval(activeInterval);
    }
  };

  const checkSessionExpiry = () => {
    if (isIdle) {
      const expiresIn = moment(tokenExpiry).diff(moment(), "seconds");

      if (expiresIn <= 120 && expiresIn > 0 && showExpiryModal !== false) {
        setShowExpiryModal(idleInterval);
      } else if (expiresIn <= 0) {
        onAutoLogout();
        clearInterval(idleInterval);
        dispatch(logout());
      }
    } else {
      clearInterval(idleInterval);
    }
  };

  const { isIdle, isActive, reset } = useIdleTimer({
    onActive,
    onIdle,
    timeout: 1000 * 60 * 1,
  });

  return null;
};

export default IdleTimer;
