import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";

import DealerQuestions from "./customer/DealerQuestions";
import DrivingInfo from "./customer/DrivingInfo";
import EnquirySource from "./customer/EnquirySource";
import Legals from "./customer/Legals";
import PersonalDetails from "./customer/PersonalDetails";
import OccupationDetails from "./customer/OccupationDetails";

const CustomerInformation = (props) => {
  const [tab, setTab] = useState("personal");

  const handleChangeDrivingTab = (val) => {
    if (props?.dealerQuestions?.length > 0) {
      setTab("dealerQuestions");
    } else {
      setTab(val);
    }
  };

  return (
    <div className="customer-information-form">
      <div className="customer-information-form-nav">
        <Nav
          variant="underline"
          activeKey={tab}
          onSelect={(val) => setTab(val)}
        >
          <Nav.Item>
            <Nav.Link eventKey="personal">Personal details</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="occupation">Occupation</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="driving">Driving information</Nav.Link>
          </Nav.Item>
          {props.dealerQuestions.length > 0 && (
            <Nav.Item>
              <Nav.Link eventKey="dealerQuestions">
                Additional information
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item>
            <Nav.Link eventKey="legals">Legals</Nav.Link>
          </Nav.Item>
          {props.application?.applicationGuid && (
            <Nav.Item>
              <Nav.Link eventKey="source">Enquiry source</Nav.Link>
            </Nav.Item>
          )}
        </Nav>
      </div>

      <div className="customer-information-form-content">
        {tab === "personal" ? (
          <PersonalDetails
            application={props.application}
            schema={props.schema}
            onUpdate={props.onUpdate}
            changeTab={setTab}
            errors={props.errors}
          />
        ) : tab === "occupation" ? (
          <OccupationDetails
            application={props.application}
            schema={props.schema}
            onUpdate={props.onUpdate}
            changeTab={setTab}
          />
        ) : tab === "driving" ? (
          <DrivingInfo
            application={props.application}
            schema={props.schema}
            onUpdate={props.onUpdate}
            changeTab={handleChangeDrivingTab}
          />
        ) : tab === "dealerQuestions" ? (
          <DealerQuestions
            application={props.application}
            questions={props.dealerQuestions}
            schema={props.schema}
            onUpdate={props.onUpdate}
            changeTab={setTab}
          />
        ) : tab === "legals" ? (
          <Legals
            application={props.application}
            schema={props.schema}
            onUpdate={props.onUpdate}
            setActiveTab={props.setActiveTab}
          />
        ) : (
          tab === "source" && <EnquirySource application={props.application} />
        )}
      </div>
    </div>
  );
};

CustomerInformation.defaultProps = {
  dealerQuestions: [],
};

export default CustomerInformation;
