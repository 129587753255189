import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as yup from "yup";

import { lookupPostcode } from "services";
import { getErrorLabels } from "helpers/errors";
import {
  getBlankApplication,
  getMonthOptions,
  getYearOptions,
} from "helpers/application";
import Error from "components/Error";
import TextInput from "components/form/TextInput";

import "react-datepicker/dist/react-datepicker.css";

const PersonalDetails = (props) => {
  const metaData = useSelector((state) => state?.lender.metaData);

  const [clientDetails, setClientDetails] = useState({
    title: "",
    maritalStatus: "",
    typeOfResidence: "",
    years: "",
    months: "0",
    dateOfBirth: "",
  });

  const [previousAddress, setPreviousAddress] = useState({
    streetNumber: "",
    streetName: "",
    postcode: "",
    months: "0",
    years: "",
  });

  const [previousAddressValidationSchema, setPreviousAddressValidationSchema] =
    useState({});
  const [validationSchema, setValidationSchema] = useState(null);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (Object.keys(props.schema).length > 0) {
      setValidationSchema(
        yup.object({
          title: yup
            .mixed()
            .oneOf(
              props?.schema?.properties?.client?.properties?.title?.enum ?? []
            ),
          firstName: yup
            .string()
            .matches(
              props?.schema?.properties?.client?.properties?.firstName?.pattern
            )
            .required(),
          lastName: yup.string().required(),
          dateOfBirth: yup
            .date()
            .test({
              name: "dateOfBirth",
              message: "Please enter a valid date",
              test: (value) => moment(value, "DD/MM/YYYY").isValid(),
            })
            .required("Please enter a valid date"),
          maritalStatus: yup
            .mixed()
            .oneOf(
              props?.schema?.properties?.client?.properties?.maritalStatus
                ?.enum ?? []
            ),
          cellNumber: yup.string().length(11).required(),
          clientEmail: yup.string().email().required(),
          streetNumber: yup
            .string()
            .matches(
              props?.schema?.properties?.client?.properties?.currentAddress
                ?.properties?.streetNumber?.pattern
            )
            .required(),
          streetName: yup
            .string()
            .matches(
              props?.schema?.properties?.client?.properties?.currentAddress
                ?.properties?.streetName?.pattern
            )
            .required(),
          postcode: yup
            .string()
            .matches(
              props?.schema?.properties?.client?.properties?.currentAddress
                ?.properties?.postcode?.pattern
            )
            .required(),
          months: yup.number().min(0).max(11).required(),
          years: yup.number().min(0).max(99).required(),
          typeOfResidence: yup
            .mixed()
            .oneOf(
              props?.schema?.properties?.client?.properties?.typeOfResidence
                ?.enum ?? []
            ),
        })
      );

      setPreviousAddressValidationSchema({
        streetNumber: yup
          .string()
          .matches(
            props?.schema?.properties?.client?.properties?.currentAddress
              ?.properties?.streetNumber?.pattern
          )
          .required(),
        streetName: yup
          .string()
          .matches(
            props?.schema?.properties?.client?.properties?.currentAddress
              ?.properties?.streetName?.pattern
          )
          .required(),
        postcode: yup
          .string()
          .matches(
            props?.schema?.properties?.client?.properties?.currentAddress
              ?.properties?.postcode?.pattern
          )
          .required(),
        months: yup.number().min(0).max(11).required(),
        years: yup.number().min(0).max(99).required(),
      });
    }
  }, [props.schema]);

  useEffect(() => {
    if (props.application) {
      let years = Math.floor(
        props?.application?.client?.addressList[0]?.monthsAtAddress / 12
      );
      let months =
        props?.application?.client?.addressList[0]?.monthsAtAddress -
        years * 12;

      setClientDetails({
        title: props.application?.client?.title,
        firstName: props.application?.client?.firstName,
        lastName: props.application?.client?.lastName,
        dateOfBirth: props.application?.client?.dateOfBirth,
        maritalStatus: props.application?.client?.maritalStatus,
        cellNumber: props.application?.client?.clientCellNumber,
        clientEmail: props.application?.client?.clientEmail,
        typeOfResidence: props.application?.client?.typeOfResidence,
        streetNumber: props?.application?.client?.addressList[0]?.addressLine1,
        streetName: props?.application?.client?.addressList[0]?.addressLine2,
        postcode: props?.application?.client?.addressList[0]?.postCode,
        months: months,
        years: years,
      });

      if (props?.application?.client?.addressList.length > 1) {
        let previousYears = Math.floor(
          props?.application?.client?.addressList[1]?.monthsAtAddress / 12
        );
        let previousMonths =
          props?.application?.client?.addressList[1]?.monthsAtAddress -
          previousYears * 12;

        setPreviousAddress({
          streetNumber:
            props?.application?.client?.addressList[1]?.addressLine1,
          streetName: props?.application?.client?.addressList[1]?.addressLine2,
          postcode: props?.application?.client?.addressList[1]?.postCode,
          months: previousMonths,
          years: previousYears,
        });
      }
    }
  }, [props.application]);

  useEffect(() => {
    const clientErrors = {};
    Object.keys(props.errors).map((key) => {
      if (key.includes("client")) {
        clientErrors[key.replace("client.", "")] = props.errors[key];
      }
    });
    setErrors(clientErrors);
  }, [props.errors]);

  const getAddress = (field = "address", current = true) => {
    let addressFields = [];

    let address;
    if (current) {
      address = props.application?.client?.addressList[0];
    } else {
      address = props?.application?.client?.addressList[1];
    }

    if (address) {
      if (field === "address") {
        [
          "addressLine1",
          "addressLine2",
          "addressLine3",
          "addressLine4",
          "addressLine5",
        ].map((field) => {
          if (address[field]) {
            addressFields.push(address[field]);
          }
        });
      } else if (field === "postcode") {
        addressFields.push(address.postCode);
      } else if (field === "time") {
        let totalMonths = address.monthsAtAddress;

        let years = Math.floor(totalMonths / 12);
        let months = (totalMonths % 12) * 12;

        if (years > 0) {
          addressFields.push(`${years} years`);
        }

        if (months > 0) {
          addressFields.push(`${months} months`);
        }
      }

      return addressFields.join(", ");
    }
    return "";
  };

  const getCurrentMonthsAtAddress = () => {
    return (
      parseInt(clientDetails.months) + parseInt(clientDetails.years ?? 0) * 12
    );
  };

  const handleInputChange = (e) => {
    setClientDetails({
      ...clientDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateOfBirthChange = (e) => {
    let value = e.target.value;
    let lastChar = (
      moment.isMoment(clientDetails.dateOfBirth)
        ? clientDetails.dateOfBirth.format("DD/MM/YYYY")
        : clientDetails.dateOfBirth
    ).replace(value, "");

    if (lastChar === "/") {
      // do nothing
    } else if (value.match(/^[0-9]{2}$/)) {
      value = value.replace(/^([0-9]{2})$/, "$1/");
    } else if (value.match(/^[0-9]{2}\/?[0-9]{2}$/)) {
      value = value.replace(/^([0-9]{2})\/?([0-9]{2})$/, "$1/$2/");
    } else if (value.match(/^[0-9]{2}\/?[0-9]{2}\/?[0-9]{4}$/)) {
      if (moment(value, "DD/MM/YYYY").isValid()) {
        value = moment(value, "DD/MM/YYYY");
      } else {
        value = value.replace(
          /^([0-9]{2})\/?([0-9]{2})\/?([0-9]{4})$/,
          "$1/$2/$3"
        );
      }
    }

    setClientDetails({
      ...clientDetails,
      dateOfBirth: value,
    });
  };

  const handleDateInputChange = (name, e) => {
    let value = e.target.value;

    if (value.length >= clientDetails[name].length) {
      if (value.length == 2 || value.length == 5) {
        value += "/";
      }

      if (value.length === 10 && moment(value, "DD-MM-YYYY").isValid()) {
        value = moment(value, "DD-MM-YYYY");
      }
    }

    setClientDetails({
      ...clientDetails,
      [name]: value,
    });
  };

  const handlePreviousAddressChange = (e) => {
    setPreviousAddress({
      ...previousAddress,
      [e.target.name]: e.target.value,
    });
  };

  const handlePostcodeLookup = (type = "current") => {
    let value;

    if (type === "current") {
      value = clientDetails.postcode;
    } else {
      value = previousAddress.postcode;
    }

    if (value) {
      lookupPostcode(value).then((res) => {
        if (type === "current") {
          if (res?.street_name) {
            setClientDetails({
              ...clientDetails,
              streetName: res.street_name,
            });
          } else if (Object.keys(res).length > 0) {
            setClientDetails({
              ...clientDetails,
              streetName: "",
            });

            setErrors({
              ...errors,
              postcode: "Please enter address manually",
            });
          } else {
            setClientDetails({
              ...clientDetails,
              streetName: "",
            });

            setErrors({
              ...errors,
              postcode: "No address found for this postcode",
            });
          }
        } else {
          if (res?.street_name) {
            setPreviousAddress({
              ...previousAddress,
              streetName: res.street_name,
            });
          } else if (Object.keys(res).length > 0) {
            setPreviousAddress({
              ...previousAddress,
              streetName: "",
            });

            setErrors({
              ...errors,
              ["previous.postcode"]: "Please enter address manually",
            });
          } else {
            setPreviousAddress({
              ...previousAddress,
              streetName: "",
            });

            setErrors({
              ...errors,
              ["previous.postcode"]: "No address found for this postcode",
            });
          }
        }
      });
    }
  };

  const handleSaveSection = () => {
    let prevAddrSchema = yup.object();
    let prevAddr = {};
    let prevAddrObj = [];

    if (getCurrentMonthsAtAddress() < 36) {
      prevAddrSchema = yup.object({
        previous: yup.object(previousAddressValidationSchema),
      });

      prevAddr = {
        streetNumber: previousAddress?.streetNumber,
        streetName: previousAddress?.streetName,
        postcode: previousAddress?.postcode,
        months: previousAddress?.months,
        years: previousAddress?.years,
      };

      prevAddrObj = [
        {
          addressLine1: previousAddress?.streetNumber,
          addressLine2: previousAddress?.streetName,
          postCode: previousAddress?.postcode,
          monthsAtAddress:
            parseInt(previousAddress?.years) * 12 +
            parseInt(previousAddress?.months),
        },
      ];
    }

    let validateData = {
      ...clientDetails,
      dateOfBirth: moment.isMoment(clientDetails?.dateOfBirth)
        ? clientDetails?.dateOfBirth.format("yyyy-MM-DD")
        : clientDetails?.dateOfBirth,
    };

    validationSchema
      .concat(prevAddrSchema)
      .validate({ ...validateData, previous: prevAddr }, { abortEarly: false })
      .then(() => {
        const createApplication = getBlankApplication(props.application);

        props.onUpdate(
          {
            client: {
              ...createApplication?.client,
              title: clientDetails?.title,
              firstName: clientDetails?.firstName,
              lastName: clientDetails?.lastName,
              dateOfBirth: moment.isMoment(clientDetails?.dateOfBirth)
                ? clientDetails?.dateOfBirth.format("yyyy-MM-DD")
                : clientDetails?.dateOfBirth,
              clientCellNumber: clientDetails?.cellNumber,
              clientEmail: clientDetails?.clientEmail,
              maritalStatus: clientDetails?.maritalStatus,
              typeOfResidence: clientDetails?.typeOfResidence,
              addressList: [
                {
                  addressLine1: clientDetails?.streetNumber,
                  addressLine2: clientDetails?.streetName,
                  postCode: clientDetails?.postcode,
                  monthsAtAddress:
                    parseInt(clientDetails?.years) * 12 +
                    parseInt(clientDetails?.months),
                },
                ...prevAddrObj,
              ],
            },
          },
          false,
          true
        );

        props.changeTab("occupation");
      })
      .catch((err) => {
        console.log(err.inner);
        setErrors(getErrorLabels(err));
      });
  };

  const getCurrentDOB = () => {
    if (clientDetails?.dateOfBirth) {
      if (moment.isMoment(clientDetails.dateOfBirth)) {
        return clientDetails.dateOfBirth.format("DD/MM/yyyy");
      }

      return clientDetails.dateOfBirth;
    } else {
      return "";
    }
  };

  const handleCustomBlur = (tabIndex) => {
    let next = document.querySelector(`*[tabindex='${tabIndex}']`);

    if (next) {
      next.focus();
    }
  };

  return (
    <div className="customer-details">
      <Row>
        <Col xs={12} md={3}>
          <label>Title</label>
          <div>
            {props.application?.applicationGuid ? (
              <>{props.application?.client?.title}</>
            ) : (
              <>
                <Form.Select
                  name="title"
                  value={clientDetails?.title}
                  onChange={handleInputChange}
                  tabIndex={1}
                >
                  <option value=""></option>
                  {metaData?.titleList?.map((option) => (
                    <option value={option} key={option}>
                      {option}
                    </option>
                  ))}
                </Form.Select>
                <Error name="title" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={6} md={3}>
          <label>First name</label>
          <div>
            {props.application?.applicationGuid ? (
              <>{props.application?.client?.firstName}</>
            ) : (
              <>
                <TextInput
                  name="firstName"
                  onChange={handleInputChange}
                  value={clientDetails?.firstName ?? ""}
                  tabIndex={2}
                  onlyLetters
                />
                <Error name="firstName" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={6} md={3}>
          <label>Last name</label>
          <div>
            {props.application?.applicationGuid ? (
              <>{props.application?.client?.lastName}</>
            ) : (
              <>
                <TextInput
                  name="lastName"
                  onChange={handleInputChange}
                  value={clientDetails?.lastName ?? ""}
                  tabIndex={3}
                  onlyLetters
                />
                <Error name="lastName" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={6} md={3}>
          <label>D/O/B</label>
          <div>
            {props.application?.applicationGuid ? (
              <>
                {moment(props.application?.client?.dateOfBirth).format(
                  "DD-MMM-YY"
                )}
              </>
            ) : (
              <>
                <TextInput
                  name="dateOfBirth"
                  placeholder="dd/mm/yyyy"
                  onChange={handleDateOfBirthChange}
                  value={getCurrentDOB()}
                  tabIndex={4}
                  isDate
                  className="w-100"
                />
                <Error name="dateOfBirth" errors={errors} />
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={3}>
          <label>Marital Status</label>
          <div>
            {props.application?.applicationGuid ? (
              <>{props.application?.client?.maritalStatus}</>
            ) : (
              <>
                <Form.Select
                  name="maritalStatus"
                  value={clientDetails?.maritalStatus}
                  onChange={handleInputChange}
                  tabIndex={5}
                >
                  <option value=""></option>
                  {metaData?.maritalStatusList?.map((option) => (
                    <option value={option} key={option}>
                      {option}
                    </option>
                  ))}
                </Form.Select>
                <Error name="maritalStatus" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={6} md={3}>
          <label>Telephone number</label>
          <div>
            {props.application?.applicationGuid ? (
              <>{props.application?.client?.clientCellNumber}</>
            ) : (
              <>
                <TextInput
                  name="cellNumber"
                  onChange={handleInputChange}
                  value={clientDetails?.cellNumber ?? ""}
                  tabIndex={6}
                  format={/^0[0-9]{0,10}$/}
                />
                <Error name="cellNumber" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={6} md={6}>
          <label>Email address</label>
          <div>
            {props.application?.applicationGuid ? (
              <>{props.application?.client?.clientEmail}</>
            ) : (
              <>
                <input
                  name="clientEmail"
                  type="email"
                  onChange={handleInputChange}
                  className="w-100"
                  value={clientDetails?.clientEmail ?? ""}
                  tabIndex={7}
                />
                <Error name="clientEmail" errors={errors} />
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <label>Postcode</label>
          <div>
            {props.application?.applicationGuid ? (
              <>{getAddress("postcode")}</>
            ) : (
              <>
                <TextInput
                  name="postcode"
                  onChange={handleInputChange}
                  value={clientDetails?.postcode ?? ""}
                  tabIndex={8}
                  format={/^[A-Za-z0-9\s]{1,8}$/}
                />
                <button
                  className="dark mt-0 focus"
                  onClick={(e) => handlePostcodeLookup("current")}
                  tabIndex={9}
                >
                  Lookup
                </button>
                <Error name="postcode" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={12} md={4}>
          <label>Address</label>
          <div>
            {props.application?.applicationGuid ? (
              <>{getAddress()}</>
            ) : (
              <>
                <TextInput
                  name="streetNumber"
                  value={clientDetails?.streetNumber ?? ""}
                  placeholder="Property Name/Number"
                  onChange={handleInputChange}
                  className="d-block w-100 mb-2"
                  tabIndex={9}
                  format={/^[A-Za-z0-9\s\-]+$/}
                />
                <Error name="streetNumber" errors={errors} />

                <input
                  name="streetName"
                  value={clientDetails?.streetName ?? ""}
                  placeholder="Street Name"
                  onChange={handleInputChange}
                  className="d-block w-100"
                  tabIndex={10}
                />
                <Error name="streetName" errors={errors} />
              </>
            )}
          </div>
        </Col>
        <Col xs={12} md={4}>
          <label>Time at current address</label>
          <div>
            {props.application?.applicationGuid ? (
              <>{getAddress("time")}</>
            ) : (
              <Row className="mb-0">
                <Col xs={6}>
                  <Form.Select
                    name="years"
                    value={clientDetails?.years}
                    onChange={handleInputChange}
                    tabIndex={11}
                  >
                    <option value=""></option>
                    {getYearOptions().map((option) => (
                      <option value={option.value} key={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Error name="years" errors={errors} />
                </Col>
                <Col xs={6}>
                  <Form.Select
                    name="months"
                    value={clientDetails?.months}
                    onChange={handleInputChange}
                    tabIndex={12}
                  >
                    <option value=""></option>
                    {getMonthOptions().map((option) => (
                      <option value={option.value} key={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                  <Error name="months" errors={errors} />
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6} md={3}>
          <label>Type of Residence</label>
          <div>
            {props.application?.applicationGuid ? (
              <>{props.application?.client?.typeOfResidence}</>
            ) : (
              <>
                <Form.Select
                  name="typeOfResidence"
                  value={clientDetails?.typeOfResidence}
                  onChange={handleInputChange}
                  tabIndex={13}
                >
                  <option value=""></option>
                  {metaData?.residenceTypeList?.map((option) => (
                    <option value={option} key={option}>
                      {option}
                    </option>
                  ))}
                </Form.Select>
                <Error name="typeOfResidence" errors={errors} />
              </>
            )}
          </div>
        </Col>
      </Row>
      {getCurrentMonthsAtAddress() < 36 && (
        <>
          <p className="additional-text">
            As the customer has been with their current address for less than 3
            years, we need details of past address.
          </p>

          <Row>
            <Col xs={12} md={4}>
              <label>Previous Postcode</label>
              <div>
                {props.application?.applicationGuid ? (
                  <>{getAddress("postcode", false)}</>
                ) : (
                  <>
                    <TextInput
                      name="postcode"
                      value={previousAddress?.postcode ?? ""}
                      onChange={handlePreviousAddressChange}
                      tabIndex={14}
                      format={/^[A-Za-z0-9\s]{1,8}$/}
                    />
                    <button
                      className="dark mt-0 focus"
                      onClick={(e) => handlePostcodeLookup("previous")}
                      tabIndex={15}
                    >
                      Lookup
                    </button>
                    <Error name="previous.postcode" errors={errors} />
                  </>
                )}
              </div>
            </Col>
            <Col xs={12} md={4}>
              <label>Previous Address</label>
              <div>
                {props.application?.applicationGuid ? (
                  <>{getAddress("address", false)}</>
                ) : (
                  <>
                    <input
                      name="streetNumber"
                      value={previousAddress?.streetNumber ?? ""}
                      placeholder="Property Name/Number"
                      onChange={handlePreviousAddressChange}
                      className="d-block w-100 mb-2"
                      tabIndex={15}
                    />
                    <Error name="previous.streetNumber" errors={errors} />

                    <input
                      name="streetName"
                      value={previousAddress?.streetName ?? ""}
                      placeholder="Street Name"
                      onChange={handlePreviousAddressChange}
                      className="d-block w-100"
                      tabIndex={16}
                    />
                    <Error name="previous.streetName" errors={errors} />
                  </>
                )}
              </div>
            </Col>
            <Col xs={12} md={4}>
              <label>Time at current address</label>
              <div>
                {props.application?.applicationGuid ? (
                  <>{getAddress("time", false)}</>
                ) : (
                  <Row className="mb-0">
                    <Col xs={6}>
                      <Form.Select
                        name="years"
                        value={previousAddress?.years}
                        onChange={handlePreviousAddressChange}
                        tabIndex={17}
                      >
                        <option value=""></option>
                        {getYearOptions().map((option) => (
                          <option value={option.value} key={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                      <Error name="previous.years" errors={errors} />
                    </Col>
                    <Col xs={6}>
                      <Form.Select
                        name="months"
                        value={previousAddress?.months}
                        onChange={handlePreviousAddressChange}
                        tabIndex={18}
                      >
                        <option value=""></option>
                        {getMonthOptions().map((option) => (
                          <option value={option.value} key={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                      <Error name="previous.months" errors={errors} />
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </>
      )}
      {!props.application?.applicationGuid && (
        <Row className="mb-0">
          <Col xs={12} className="d-flex justify-content-end">
            <button
              className="dark focus"
              onClick={handleSaveSection}
              tabIndex={19}
            >
              Save customer & continue
            </button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PersonalDetails;
