import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import * as yup from "yup";

import { getErrorLabels } from "helpers/errors";
import Error from "components/Error";

const Legals = (props) => {
  const [checkboxes, setCheckboxes] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setCheckboxes({
      acceptTAndCDate: props.application?.acceptTAndCDate !== null,
      acceptPrivacyPolicy: props.application?.acceptPrivacyPolicy !== null,
      acceptInitialDisclosure:
        props.application?.acceptInitialDisclosure !== null,
      acceptCommissionDisclosure:
        props.application?.acceptCommissionDisclosure !== null,
    });
  }, [props.application]);

  const validationSchema = yup.object({
    acceptTAndCDate: yup.boolean().required(),
    acceptPrivacyPolicy: yup.boolean().required(),
    acceptInitialDisclosure: yup.boolean().required(),
    acceptCommissionDisclosure: yup.boolean().required(),
  });

  const handleInputChange = (e) => {
    setCheckboxes({
      ...checkboxes,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveSection = (e) => {
    validationSchema
      .validate(checkboxes, { abortEarly: false })
      .then(() => {
        setErrors({});
        props.onUpdate({
          acceptTAndCDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          acceptPrivacyPolicy: moment().format("YYYY-MM-DD HH:mm:ss"),
          acceptInitialDisclosure: moment().format("YYYY-MM-DD HH:mm:ss"),
          acceptCommissionDisclosure: moment().format("YYYY-MM-DD HH:mm:ss"),
        });
        props.setActiveTab("vehicle");
      })
      .catch((err) => {
        setErrors(getErrorLabels(err));
      });
  };

  return (
    <div className="customer-details">
      <Row>
        <Col xs={12}>
          <label>Customer was emailed a copy of the T&C’s on</label>
          <div>
            {props?.application?.acceptTAndCDate ? (
              <>
                {moment(props.application?.acceptTAndCDate).format(
                  "DD-MMM-YY HH:mm"
                )}
              </>
            ) : (
              <>
                <Form.Check
                  name="acceptTAndCDate"
                  inline
                  value={true}
                  label="I confirm"
                  onChange={handleInputChange}
                />
                <Error name="acceptTAndCDate" errors={errors} />
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <label>Customer was emailed a copy of the Privacy Policy on</label>
          <div>
            {props?.application?.acceptPrivacyPolicy ? (
              <>
                {moment(props.application?.acceptPrivacyPolicy).format(
                  "DD-MMM-YY HH:mm"
                )}
              </>
            ) : (
              <>
                <Form.Check
                  name="acceptPrivacyPolicy"
                  inline
                  value={true}
                  label="I confirm"
                  onChange={handleInputChange}
                />
                <Error name="acceptPrivacyPolicy" errors={errors} />
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <label>
            Customer was emailed a copy of the Initial Disclosure Document on
          </label>
          <div>
            {props.application?.acceptInitialDisclosure ? (
              <>
                {moment(props.application?.acceptInitialDisclosure).format(
                  "DD-MMM-YY HH:mm"
                )}
              </>
            ) : (
              <>
                <Form.Check
                  name="acceptInitialDisclosure"
                  inline
                  value={true}
                  label="I confirm"
                  onChange={handleInputChange}
                />
                <Error name="acceptInitialDisclosure" errors={errors} />
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <label>
            Customer was emailed a copy of the Commission Disclosure Document on
          </label>
          <div>
            {props.application?.acceptCommissionDisclosure ? (
              <>
                {moment(props.application?.acceptCommissionDisclosure).format(
                  "DD-MMM-YY HH:mm"
                )}
              </>
            ) : (
              <>
                <Form.Check
                  name="acceptCommissionDisclosure"
                  inline
                  value={true}
                  label="I confirm"
                  onChange={handleInputChange}
                />
                <Error name="acceptCommissionDisclosure" errors={errors} />
              </>
            )}
          </div>
        </Col>
      </Row>

      {!props.application?.applicationGuid && (
        <Row className="mb-0">
          <Col xs={12} className="d-flex justify-content-end">
            <button className="dark focus" onClick={handleSaveSection}>
              Save customer & continue
            </button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Legals;
