import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { adjustQuote, getAdjustQuoteSchema, getApplication } from "services";
import { getBlankApplication } from "helpers/application";
import { getVehicleName } from "helpers/vehicle";
import { setPageLoading, setPageLoadingMsg } from "store/actions/auth";
import { ReactComponent as DownArrow } from "assets/images/down-arrow.svg";
import { ReactComponent as QuoteBuilderDownArrow } from "assets/images/quote-builder-arrow.svg";
import { ReactComponent as TickIcon } from "assets/images/tick.svg";
import { ReactComponent as WarningIcon } from "assets/images/warning.svg";
import QuoteTermsModal from "components/modals/QuoteTermsModal";

const QuoteBuilder = (props) => {
  const dispatch = useDispatch();

  const [deposit, setDeposit] = useState(0);
  const [includePx, setIncludePx] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loanTermOptions, setLoanTermOptions] = useState([]);
  const [maxMileage, setMaxMileage] = useState(null);
  const [mileage, setMileage] = useState(0);
  const [pxEquity, setPxEquity] = useState(0);
  const [term, setTerm] = useState(null);
  const [showChangeTermsModal, setShowChangeTermsModal] = useState(false);
  const [showDepositInput, setShowDepositInput] = useState(false);
  const [showMileageInput, setShowMileageInput] = useState(false);
  const [showMobileDetails, setShowMobileDetails] = useState(false);
  const [showPxMenu, setShowPxMenu] = useState(false);
  const [showTermMenu, setShowTermMenu] = useState(false);

  useEffect(() => {
    getAdjustQuoteSchema().then((res) => {
      if (res.properties?.loanTerm) {
        let min = res.properties?.loanTerm?.minimum ?? 12;
        let max = res.properties?.loanTerm?.maximum ?? 60;
        let step = res.properties?.loadTerm?.multipleOf ?? 6;

        let options = [];
        while (min <= max) {
          options.push({ value: min, label: `${min} months` });

          min += step;
        }

        setLoanTermOptions(options);
      }

      if (res.properties?.mileage) {
        setMaxMileage(res.properties?.mileage?.maximum);
      }
    });
  }, []);

  useEffect(() => {
    if (props?.application) {
      setDeposit(props?.application?.deposit);
      setMileage(props?.application?.annualMileage);
      setTerm(props?.application?.term);
      setIncludePx(props?.application?.pxDeposite);
      setPxEquity(props?.application?.equity);
    }
  }, [props.application]);

  const handleQuoteChange = (e) => {
    if (e.target.name === "mileage") {
      setMileage(parseInt(e.target.value || 0));
    } else if (e.target.name === "deposit") {
      setDeposit(parseInt(e.target.value || 0));
    }

    props.onQuoteChange(true);
  };

  const handleTermChange = (value) => {
    setTerm(parseInt(value));

    props.onQuoteChange(true);
  };

  const handleIncludePxChange = (val) => {
    if (val === "false") {
      setPxEquity(0);
      setIncludePx(false);
    } else {
      setPxEquity(props?.application?.equity);
      setIncludePx(true);
    }

    props.onQuoteChange(true);
  };

  const handleShowPxMenu = () => {
    if (props.application && !showPxMenu) {
      setShowPxMenu(!showPxMenu);
    }
  };

  const handleShowTermMenu = () => {
    if (props.application && !showTermMenu) {
      setShowTermMenu(!showTermMenu);
    }
  };

  const handleShowMileageInput = () => {
    if (props.application && !showMileageInput) {
      setShowMileageInput(true);
    }
  };

  const handleShowDepositInput = () => {
    if (props.application && !showDepositInput) {
      setShowDepositInput(true);
    }
  };

  const handleQuoteButtonClick = () => {
    if (props.application?.applicationGuid) {
      if (
        !props.vehicleChanged &&
        (props?.application?.annualMileage > mileage ||
          props?.application?.term < term)
      ) {
        setShowChangeTermsModal(true);
      } else if (props.onClone && props.vehicleChanged) {
        setLoading(true);
        dispatch(
          setPageLoadingMsg(
            "We are updating your changes with the lender's criteria. This could take up to 30 seconds, thank you for your patience."
          )
        );
        dispatch(setPageLoading(true));

        props.onClone({
          annualMileage: mileage,
          deposit,
          term,
          pxDeposite: includePx,
        });
      } else {
        handleAdjustQuote();
      }
    } else {
      handleAdjustQuote();
    }
  };

  const handleAdjustQuote = () => {
    setLoading(true);
    dispatch(
      setPageLoadingMsg(
        "We are updating your changes with the lender's criteria. This could take up to 30 seconds, thank you for your patience."
      )
    );
    dispatch(setPageLoading(true));

    if (props.application?.applicationGuid) {
      adjustQuote({
        applicationGuid: props.application.applicationGuid,
        despositAmount: deposit,
        includEquity: pxEquity !== 0,
        loanTerm: term,
        mileage,
      })
        .then((res) => {
          const newQuote = res;

          getApplication({
            applicationGuid: props.application.applicationGuid,
          }).then((res) => {
            props.setApplication(res);
            props.setQuotes(newQuote);
            props.onQuoteChange(false);
            props.setActiveTab("prequalified");
            dispatch(setPageLoading(false));
            setLoading(false);
            setShowChangeTermsModal(false);
          });
        })
        .catch((res) => {
          dispatch(setPageLoading(false));
          setLoading(false);
          setShowChangeTermsModal(false);
        });
    } else {
      let applicationObj = getBlankApplication(props.application);

      props.onUpdate(
        {
          annualMileage: mileage,
          deposit,
          term,
          pxDeposite: applicationObj.pxDeposite,
        },
        true
      );
    }
  };

  return (
    <div className={`quote-builder ${showMobileDetails ? "show-mobile" : ""}`}>
      <h3 onClick={(e) => setShowMobileDetails(!showMobileDetails)}>
        Quote builder
        <QuoteBuilderDownArrow className="d-block d-md-none" />
      </h3>

      <Row>
        <Col xs={5}>Vehicle:</Col>
        <Col xs={7}>{getVehicleName(props?.application?.vehicle)}</Col>
      </Row>
      <Row>
        <Col xs={5}>Customer:</Col>
        <Col xs={7}>
          {props?.application?.client?.title}{" "}
          {props?.application?.client?.firstName}{" "}
          {props?.application?.client.lastName}
        </Col>
      </Row>
      <Row>
        <Col xs={5}>Part exchange equity:</Col>
        <Col xs={7}>
          {props.application?.equity && (
            <>£{pxEquity.toLocaleString("en-GB")}</>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={5}>Include part-ex?</Col>
        <Col xs={7} onClick={handleShowPxMenu} className="cursor-pointer">
          {props.application && (
            <>
              {props.application?.pxVehcile && (
                <DownArrow className="quote-amend-arrow" />
              )}

              {includePx ? "Yes" : "No"}

              {props.application?.pxVehcile && (
                <Dropdown
                  show={showPxMenu}
                  onToggle={(show) => setShowPxMenu(show)}
                  onSelect={(value) => handleIncludePxChange(value)}
                >
                  <Dropdown.Menu align="end">
                    <Dropdown.Item eventKey={true}>Yes</Dropdown.Item>
                    <Dropdown.Item eventKey={false}>No</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={5}>Quote term:</Col>
        <Col xs={7} onClick={handleShowTermMenu} className="cursor-pointer">
          {props.application && (
            <>
              <DownArrow className="quote-amend-arrow" />
              {term} months
              {term === 0 && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Please select a term</Tooltip>}
                >
                  <WarningIcon className="quote-amend-warning" />
                </OverlayTrigger>
              )}
              <Dropdown
                show={showTermMenu}
                onToggle={(show) => setShowTermMenu(show)}
                onSelect={(value) => handleTermChange(value)}
              >
                <Dropdown.Menu align="end">
                  {loanTermOptions.map((option) => (
                    <Dropdown.Item key={option.value} eventKey={option.value}>
                      {option.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={5}>Annual mileage:</Col>
        <Col xs={7} onClick={handleShowMileageInput} className="cursor-pointer">
          {props.application && (
            <>
              {showMileageInput ? (
                <>
                  <input
                    type="text"
                    name="mileage"
                    value={mileage}
                    onChange={handleQuoteChange}
                  />
                  <TickIcon onClick={(e) => setShowMileageInput(false)} />
                </>
              ) : (
                <>
                  <DownArrow className="quote-amend-arrow" />
                  <>{mileage.toLocaleString("en-GB")}</>
                </>
              )}

              {mileage === 0 && (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Please enter a mileage</Tooltip>}
                >
                  <WarningIcon className="quote-amend-warning" />
                </OverlayTrigger>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={5}>Vehicle sales value:</Col>
        <Col xs={7}>
          {props.application && (
            <>
              £{props?.application?.vehicle?.salesValue.toLocaleString("en-GB")}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={5}>Cash deposit:</Col>
        <Col xs={7} onClick={handleShowDepositInput} className="cursor-pointer">
          {props.application && (
            <>
              {showDepositInput ? (
                <>
                  <input
                    type="text"
                    name="deposit"
                    value={deposit}
                    onChange={handleQuoteChange}
                  />
                  <TickIcon onClick={(e) => setShowDepositInput(false)} />
                </>
              ) : (
                <>
                  <DownArrow className="quote-amend-arrow" />£
                  {deposit.toLocaleString("en-GB")}
                </>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={5}>Total deposit:</Col>
        <Col xs={7}>
          {props.application && (
            <>
              £
              {(
                deposit + (includePx ? props?.application?.equity : 0)
              ).toLocaleString("en-GB")}
            </>
          )}
        </Col>
      </Row>
      {props.application && (
        <Row>
          <Col xs={12} className="mt-3">
            <button
              onClick={handleQuoteButtonClick}
              className="pink"
              disabled={!mileage || !term}
            >
              {props.application?.applicationGuid ? (
                <>Recalculate quote</>
              ) : (
                <>Pre-qualify quote</>
              )}
            </button>
          </Col>
        </Row>
      )}

      <QuoteTermsModal
        show={showChangeTermsModal}
        showTermWarning={props?.application?.term < term}
        showMileageWarning={props?.application?.annualMileage > mileage}
        onHide={(e) => setShowChangeTermsModal(false)}
        onAccept={handleAdjustQuote}
        loading={loading}
      />
    </div>
  );
};

QuoteBuilder.defaultProps = {
  application: null,
  onQuoteChange: () => {},
  vehicleChanged: false,
};

export default QuoteBuilder;
