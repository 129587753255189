import authTypes from "store/types/auth";

export const loginSuccessful = (data) => ({
  type: authTypes.LOGIN_SUCCESSFUL,
  payload: data,
});

const logoutSuccessful = () => ({
  type: authTypes.LOGOUT,
});

export const setNotifications = (data) => ({
  type: authTypes.SET_NOTIFICATIONS,
  payload: data,
});

export const setPageLoading = (data) => ({
  type: authTypes.SET_LOADING,
  payload: data,
});

export const setPageLoadingMsg = (data) => ({
  type: authTypes.SET_LOADING_MSG,
  payload: data,
});

export const setIsBHDealer = (data) => ({
  type: authTypes.SET_IS_BH_DEALER,
  payload: data,
});

export const logout = () => async (dispatch) => {
  await dispatch(logoutSuccessful());
};

export const setErrorMessage = (data) => ({
  type: authTypes.SET_ERROR_MESSAGE,
  payload: data,
});
