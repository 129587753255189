import React from "react";
import { Modal } from "react-bootstrap";

const ErrorModal = (props) => {
  const getFieldLabel = (field) => {
    return field.replace(/\./, " - ").replace(/([A-Z])/g, " $1");
  };

  return (
    <>
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        className="product-info-modal blurred"
      >
        <Modal.Header closeButton>Error(s) Detected</Modal.Header>
        <Modal.Body>
          <p>
            The following errors were detected. Please correct them and try
            again.
          </p>

          {Object.keys(props.errors).map((field) => (
            <p key={field}>
              <span style={{ textTransform: "capitalize", fontWeight: 600 }}>
                {getFieldLabel(field)}:
              </span>
              <span> {props.errors[field]}</span>
            </p>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ErrorModal;
