import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { loginSuccessful } from "store/actions/auth";
import { loginUser } from "services";
import Error from "components/form/Error";

import moment from "moment";

const LoginPage = (props) => {
  const dispatch = useDispatch();

  const [error, setError] = useState(false);

  const schema = Yup.object().shape({
    username: Yup.string()
      .email("Must be an email address")
      .required("This field is required"),
    password: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      loginUser(values)
        .then((res) => {
          dispatch(loginSuccessful(res));
        })
        .catch((err) => {
          if (err?.response) {
            switch (err.response.status) {
              case 423:
                setError(
                  "Your account has been locked. Please contact an administrator for more information."
                );
                break;
              case 401:
                setError(
                  "No account found for the email address and password provided. Please try again or reset your password."
                );
                break;
            }
          }
        });
    },
  });

  return (
    <>
      <h3>Please sign in below.</h3>

      {error && (
        <Alert variant="danger" className="mt-4">
          {error}
        </Alert>
      )}

      <form onSubmit={formik.handleSubmit} className="form-container mt-auto">
        <div className="form-item">
          <label>Email address</label>
          <input
            type="text"
            name="username"
            placeholder="Enter your email"
            value={formik.values.username}
            onChange={formik.handleChange}
          />
          <Error error={formik?.errors?.username} />
        </div>
        <div className="form-item">
          <label>Password</label>
          <input
            type="password"
            name="password"
            placeholder="Enter your password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          <Error error={formik?.errors?.password} />
        </div>

        <button type="submit" className="primary">
          Login
        </button>

        <Link to="/forgot" className="form-link">
          Forgot your password? Reset it.
        </Link>
      </form>
    </>
  );
};

export default LoginPage;
