import React from "react";

const TextInput = ({
  onChange,
  onlyNumbers,
  onlyLetters,
  isDate,
  format,
  ...rest
}) => {
  const handleChange = (e) => {
    if (onlyNumbers) {
      if (e.target.value !== "" && !e.target.value.match(/^[0-9]+$/)) {
        return false;
      }
    } else if (onlyLetters) {
      if (e.target.value !== "" && !e.target.value.match(/^[A-Za-z\-\s]+$/)) {
        return false;
      }
    } else if (isDate) {
      const format = /^\d{1,2}\/{0,1}\d{0,2}\/{0,1}\d{0,4}$/;
      if (e.target.value !== "" && !e.target.value.toString().match(format)) {
        return false;
      }
    } else if (format) {
      if (e.target.value !== "" && !e.target.value.match(format)) {
        return false;
      }
    }
    onChange(e);
  };

  return <input {...rest} onChange={handleChange} />;
};

export default TextInput;
