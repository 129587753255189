const authTypes = {
  LOGIN_SUCCESSFUL: "AUTH_LOGIN_SUCCESSFUL",
  LOGOUT: "AUTH_LOGOUT",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  SET_LOADING: "SET_LOADING",
  SET_LOADING_MSG: "SET_LOADING_MSG",
  SET_IS_BH_DEALER: "SET_IS_BH_DEALER",
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
};

export default authTypes;
