import { persistReducer } from "redux-persist";
import { CookieStorage } from "redux-persist-cookie-storage";
import storage from "redux-persist/lib/storage";
import Cookies from "js-cookie";
import authTypes from "store/types/auth";

const initialState = {
  user: null,
  isBHDealer: false,
  token: null,
  expiry: null,
  isAdmin: null,
  dealerGuid: null,
  notifications: null,
  loading: false,
  loadingMsg: null,
  errorMessage: null,
};

const cookieConfig = {
  key: "auth",
  storage: new CookieStorage(Cookies),
  whitelist: ["token", "expiry", "isAdmin", "dealerGuid", "isBHDealer"],
};

const localStorageConfig = {
  key: "auth",
  storage,
  whitelist: ["token", "expiry", "isAdmin", "dealerGuid", "isBHDealer"],
};

export const authReducer = persistReducer(
  localStorageConfig,
  (state = initialState, { type, payload }) => {
    switch (type) {
      case authTypes.LOGIN_SUCCESSFUL:
        return {
          ...state,
          token: payload.token,
          expiry: payload.expiryDate,
          isAdmin: payload.admin,
          dealerGuid: payload.dealerGuid,
          notifications: null,
        };

      case authTypes.LOGOUT:
        return {
          ...state,
          token: null,
          expiry: null,
          isAdmin: null,
          notifications: null,
        };

      case authTypes.SET_NOTIFICATIONS:
        return {
          ...state,
          notifications: payload,
        };

      case authTypes.SET_LOADING:
        return {
          ...state,
          loading: payload,
        };

      case authTypes.SET_LOADING_MSG:
        return {
          ...state,
          loadingMsg: payload,
        };

      case authTypes.SET_IS_BH_DEALER:
        return {
          ...state,
          isBHDealer: payload,
        };

      case authTypes.SET_ERROR_MESSAGE:
        return {
          ...state,
          errorMessage: payload,
        };

      default:
        return state;
    }
  }
);
