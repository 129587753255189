import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";

import { getBlankApplication } from "helpers/application";
import { getPartExchange, getPartExchangeSchema, getVrmSearch } from "services";
import TextInput from "components/form/TextInput";
import WarningModal from "components/modals/WarningModal";

const PartExchange = (props) => {
  const [equity, setEquity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mileage, setMileage] = useState(0);
  const [notes, setNotes] = useState("");
  const [pxVehicle, setPxVehicle] = useState(null);
  const [searchVrm, setSearchVrm] = useState("");
  const [settlement, setSettlement] = useState(0);
  const [valuation, setValuation] = useState(0);
  const [vrmError, setVrmError] = useState(false);

  useEffect(() => {
    getPartExchangeSchema();
  }, []);

  useEffect(() => {
    if (props.application?.pxVehcile?.registrationNumber) {
      setSearchVrm(props.application?.pxVehcile?.registrationNumber);
      setPxVehicle(props.application?.pxVehcile);
      setMileage(props.application.pxMileage ?? 0);
      setValuation(props?.application?.estimatedValue ?? 0);
      setSettlement(props?.application?.outstandingFinanceValue ?? 0);
      setEquity(props?.application?.equity ?? 0);
      setNotes(props?.application?.pxVehcile?.notes ?? "");
    }
  }, [props.application]);

  const handleVrmSearch = () => {
    if (searchVrm) {
      setLoading(true);

      getVrmSearch({ pxVehicleVRM: searchVrm.replace(" ", "") })
        .then((res) => {
          setPxVehicle({
            make: res.make,
            model: res.model,
            yearOfManufacture: res.modelYear,
            colour: res.colour,
            fuelType: res.fuelType,
            engineCapacity: res.engineCapacity,
            vin: res.vin,
          });

          setSearchVrm(res.pxVehicleVRM);
          setLoading(false);

          if (
            props.application?.pxVehcile?.registrationNumber.replace(
              " ",
              ""
            ) !== searchVrm.replace(" ", "")
          ) {
            setMileage(0);
            setValuation(0);
            setSettlement(0);
            setEquity(0);
            setNotes("");
          }
        })
        .catch((err) => {
          setVrmError(true);
          console.log("err", err);
          setLoading(false);
        });
    }
  };

  const handleGetValuation = () => {
    getPartExchange({
      pxVehicleVRM: searchVrm.replace(" ", ""),
      pxVehicleMileage: `${mileage}`,
    })
      .then((res) => {
        setValuation(res.valuation);
        setEquity(res.valuation);
      })
      .catch((err) => {
        setVrmError(true);
        console.log("err", err);
      });
  };

  const handleMileageChange = (e) => {
    if (e.target.value) {
      setMileage(parseInt(e.target.value.replace(",", "")));
      setValuation(0);
    } else {
      setMileage(e.target.value);
    }
  };

  const handleValueChange = (e) => {
    let value = parseInt(e.target.value.replace(",", "").replace("£", ""));
    setValuation(value);
    setEquity(value - parseInt(settlement));
  };

  const handleSettlementChange = (e) => {
    var value;
    if (!e.target.value) {
      value = 0;
    } else {
      value = parseInt(e.target.value.replace(",", "").replace("£", ""));
    }

    setSettlement(parseInt(value));
    setEquity(parseInt(valuation) - parseInt(value));
  };

  const updateApplication = () => {
    if (props.application?.applicationGuid) {
      const applicationHasChanged =
        props.application?.pxVehcile?.registrationNumber.replace(" ", "") !==
          searchVrm.replace(" ", "") ||
        props.application?.pxMileage !== mileage ||
        props.application?.outstandingFinanceValue !== settlement ||
        props.application?.estimatedValue !== valuation;

      props.onUpdate(
        {
          pxDeposite: true,
          pxVehcile: {
            pxVehicleRegistrationNumber: searchVrm.replace(" ", ""),
            mileage,
            outstandingFinance: settlement,
            partExValuation: valuation,
            pxNotes: notes,
          },
        },
        true
      );

      if (applicationHasChanged && props.setQuoteChanged) {
        props.setQuoteChanged(true);
      }
    } else {
      let applicationObj = getBlankApplication(props.application);

      props.onUpdate({
        pxDeposite: true,
        pxMileage: mileage,
        outstandingFinanceValue: settlement,
        estimatedValue: valuation,
        equity: parseInt(valuation) - parseInt(settlement),
        pxVehcile: {
          ...pxVehicle,
          registrationNumber: searchVrm.replace(" ", ""),
          notes,
        },
      });

      props.setActiveTab(null);
    }
  };

  return (
    <div className="customer-information-form">
      <div className="customer-information-form-content">
        <div className="customer-details">
          <Row className="align-items-center">
            <Col xs={12} md={4} className="d-flex align-items-center">
              <label className="large">Enter vehicle registration:</label>
            </Col>
            <Col xs={12} md={8} className="d-flex align-items-center">
              <Row className="mb-0">
                <Col xs={6}>
                  <TextInput
                    value={searchVrm}
                    onChange={(e) => setSearchVrm(e.target.value)}
                    className="vehicle-reg-input me-3"
                    tabIndex={1}
                    format={/^[a-zA-Z0-9\s]{1,8}$/}
                  />
                </Col>
                <Col xs={6}>
                  <button
                    onClick={handleVrmSearch}
                    className="pink focus"
                    tabIndex={2}
                  >
                    {loading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      <>Add P/X</>
                    )}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>

          {pxVehicle && (
            <>
              <Row>
                <Col xs={3} md={2}>
                  <label>Make</label>
                  <div>{pxVehicle?.make}</div>
                </Col>
                <Col xs={3} md={2}>
                  <label>Model</label>
                  <div>{pxVehicle?.model}</div>
                </Col>
                <Col xs={3} md={2}>
                  <label>Model year</label>
                  <div>{pxVehicle?.yearOfManufacture}</div>
                </Col>
                <Col xs={3} md={2}>
                  <label>Colour</label>
                  <div>{pxVehicle?.colour}</div>
                </Col>
                <Col xs={3} md={4}>
                  <label>VIN</label>
                  <div>{pxVehicle?.vin}</div>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={3}>
                  <label>Mileage</label>
                  <input
                    type="text"
                    name="mileage"
                    value={mileage.toLocaleString("en-GB")}
                    onChange={handleMileageChange}
                    className="right w-100"
                    tabIndex={3}
                  />
                </Col>
                <Col xs={6} md={3} className="d-flex align-items-end">
                  <button
                    className="dark m-0 w-100 focus"
                    onClick={handleGetValuation}
                    tabIndex={4}
                  >
                    Get valuation
                  </button>
                </Col>
              </Row>
              {valuation !== 0 && (
                <>
                  <Row className="align-items-end mb-0">
                    <Col xs={6} md={4}>
                      <label>Vehicle Value</label>
                      <input
                        type="text"
                        name="value"
                        value={
                          valuation
                            ? `£${valuation.toLocaleString("en-GB")}`
                            : "£0"
                        }
                        onChange={handleValueChange}
                        tabIndex={5}
                      />
                    </Col>
                    <Col xs={6} md={4}>
                      <label>Settlement figure</label>
                      <input
                        type="text"
                        name="settlement"
                        value={
                          settlement
                            ? `£${settlement.toLocaleString("en-GB")}`
                            : "£0"
                        }
                        onChange={handleSettlementChange}
                        tabIndex={6}
                      />
                    </Col>
                    <Col xs={6} md={4}>
                      <label>Part ex equity</label>
                      <div>£{equity ? equity.toLocaleString("en-GB") : 0}</div>
                    </Col>
                    <Col md={9} className="d-none d-md-block mt-4">
                      <label>Notes</label>
                      <div>
                        <textarea
                          name="notes"
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                          tabIndex={7}
                        />
                      </div>
                    </Col>
                    <Col xs={6} md={12} className="d-flex">
                      <button
                        onClick={updateApplication}
                        className="dark ms-auto focus"
                        tabIndex={9}
                      >
                        Save part exchange
                      </button>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {vrmError && (
        <WarningModal
          show={vrmError}
          text={"Please enter a valid vehicle registration number"}
          onCancel={(e) => setVrmError(false)}
        />
      )}
    </div>
  );
};

export default PartExchange;
