import React, { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { formatCurrency } from "helpers/currency";
import { getLendersList } from "services";
import { setLender } from "store/actions/lenders";
import LenderLogo from "components/LenderLogo";
import PageSwitcher from "components/PageSwitcher";
import Pill from "components/Pill";
import ResponsiveTable from "components/table/ResponsiveTable";

const LenderListPage = (props) => {
  const [lendersListData, setLendersListData] = useState([]);

  const frequency = useSelector((state) => state?.lender?.frequency);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getLendersList({ frequency }).then((res) => {
      setLendersListData(res?.filter((lender) => lender.enabled));
    });
  }, [frequency]);

  const getTotalApplications = (row) => {
    return parseInt(
      row?.totalPreValidated +
      row?.totalDealerApproved +
      row?.totalApproved +
      row?.totalDealerPreValidated
    );
  };

  const handleLenderClick = (row) => {
    dispatch(setLender(row));
    navigate(`/customers?lender=${row.lenderGuid}`);
  };

  return (
    <div className="lenders-list-page">
      <div className="page-top">
        <h3 className="page-title">Lenders</h3>

        <PageSwitcher
          options={[
            {
              title: "Today",
              value: "Day",
            },
            {
              title: "Week",
              value: "Week",
            },
            {
              title: "Month",
              value: "Month",
            },
            {
              title: "Year",
              value: "Year",
            },
          ]}
          selected={frequency}
        />
      </div>

      <div className="mt-4">
        <ResponsiveTable
          type="bordered"
          columns={[
            {
              title: "Lender",
              render: (row) => (
                <LenderLogo
                  image={row.lenderLogo}
                  onClick={(e) => handleLenderClick(row)}
                />
              ),
              showMobileHeader: false,
              order: 1,
            },
            {
              title: "Pre-qualifications (%)",
              render: (row) => (
                <div className="chart-cell">
                  <div className="chart-container">
                    {/*
                    <div className="chart">
                      <PieChart
                        data={[
                          {
                            title: "Pre-Validated",
                            value: row.totalPreValidatedPercentage ?? 0,
                            color: "#88FB00",
                          }
                        ]}
                        background="#F6F6F6"
                        totalValue={100}
                      />
                    </div>
                    */}
                    <div className="chart-right">
                      {row.totalPreValidatedPercentage}%
                    </div>
                  </div>
                  <span className="info-text">
                    {row.totalPreValidated}/{row.totalDealerPreValidated}{" "}
                    Applications pre-qualified
                  </span>
                </div>
              ),
              showMobileHeader: true,
              order: 3,
            },
            {
              title: "Approval performance (%)",
              render: (row) => (
                <div className="chart-cell">
                  <div className="chart-container">
                    {/*
                    <div className="chart">
                      <PieChart
                        data={[
                          {
                            title: "Approved",
                            value: row.totalApprovedPercentage ?? 0,
                            color: "#88FB00",
                          },
                        ]}
                        background="#F6F6F6"
                        totalValue={100}
                      />
                    </div>
                    */}
                    <div className="chart-right">
                      {row.totalApprovedPercentage ?? 0}%
                    </div>
                  </div>
                  <span className="info-text">
                    {row.totalApproved}/{row.totalDealerApproved} Applications
                    approved
                  </span>
                </div>
              ),
              showMobileHeader: true,
              order: 4,
            },
            {
              title: "Value of finance funded",
              render: (row) => (
                <span className="currency-cell">
                  £{formatCurrency(row.totalAmountFunded)}
                </span>
              ),
              showMobileHeader: true,
              order: 2,
            },
            {
              title: "Finance products",
              render: (row) => (
                <div className="pill-list-container">
                  {row.financeProductList.map((product) => (
                    <Pill
                      name={product.productName}
                      enabled={product.enabled}
                      key={product.productName}
                    />
                  ))}
                </div>
              ),
              showMobileHeader: false,
              mobileWidth: 6,
              order: 5,
            },
            {
              title: "Status",
              render: (row) => (
                <div className="pill-list-container">
                  <Pill name="Activated" enabled={row.enabled} />
                </div>
              ),
              showMobileHeader: false,
              mobileWidth: 3,
              order: 6,
            },
            {
              title: "First string lender",
              render: (row) => (
                <div className="pill-list-container">
                  <Pill name="First string" enabled={row.firstString} />
                </div>
              ),
              showMobileHeader: false,
              mobileWidth: 3,
              order: 7,
            },
          ]}
          data={lendersListData}
        />
      </div>
    </div>
  );
};

export default LenderListPage;
