import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  createApplication,
  getApplication,
  getApplicationSchema,
  getClientPreQualifiedQuotes,
  updateApplication,
  getDealerQuestions,
} from "services";
import {
  getApplicationUpdateObject,
  getApplicationCreateObject,
} from "helpers/application";
import {
  setPageLoading,
  setPageLoadingMsg,
  setIsBHDealer,
  setErrorMessage,
} from "store/actions/auth";
import { setCurrentQuote } from "store/actions/lenders";
import ApplicationInformationForm from "components/form/application/ApplicationInformationForm";
import QuoteBuilder from "components/QuoteBuilder";

const ApplicationInfoPage = (props) => {
  const { guid } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("prequalified");
  const [application, setApplication] = useState({});
  const [applicationSchema, setApplicationSchema] = useState({});
  const [dealerQuestions, setDealerQuestions] = useState([]);
  const [quoteHasChanged, setQuoteHasChanged] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [quoteSortBy, setQuoteSortBy] = useState("payments");
  const [vehicleChanged, setVehicleChanged] = useState(false);

  const currentQuote = useSelector((state) => state?.lender?.currentQuote);
  const dealerGuid = useSelector((state) => state?.auth?.dealerGuid);

  useEffect(() => {
    dispatch(
      setPageLoadingMsg(
        "We are retrieving the latest quotes from the lenders. This could take up to 30 seconds, thank you for your patience."
      )
    );
    dispatch(setPageLoading(true));

    getApplication({ applicationGuid: guid })
      .then((res) => {
        setApplication(res);
      })
      .catch((err) => {
        dispatch(setPageLoading(false));
      });

    getApplicationSchema().then((res) => {
      setApplicationSchema(res);
    });

    if (!currentQuote || currentQuote?.applicationGuid !== guid) {
      getClientPreQualifiedQuotes({ applicationGuid: guid })
        .then((res) => {
          handleQuoteSort(quoteSortBy, res);
          dispatch(setPageLoading(false));
          setActiveTab("prequalified");
        })
        .catch((err) => {
          dispatch(setPageLoading(false));
          setQuotes({
            lenderList: [],
          });
        });
    } else {
      setQuotes(currentQuote);
      dispatch(setPageLoading(false));
      dispatch(setCurrentQuote(null));
    }

    getDealerQuestions(dealerGuid)
      .then((res) => {
        setDealerQuestions(res);
        dispatch(setIsBHDealer(res?.length > 0));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [guid]);

  const handleQuoteSort = (field, applicationQuotes = {}) => {
    setQuoteSortBy(field);
    let currentQuotes =
      Object.keys(applicationQuotes).length > 0 ? applicationQuotes : quotes;
    let localQuotes = [...currentQuotes?.lenderList];

    let newQuotes = [];

    // Break multiple quotes into single quotes
    localQuotes.forEach((lender) => {
      lender.quoteList.forEach((q) => {
        newQuotes.push({
          ...lender,
          key: lender?.lenderName + "-" + q?.quoteGuid,
          quoteList: [q],
        });
      });
    });

    newQuotes.sort((a, b) => {
      return a.quoteList[0][field] - b.quoteList[0][field];
    });

    setQuotes({
      ...currentQuotes,
      lenderList: newQuotes,
    });
  };

  const handleApplicationUpdate = (values, pxChanged = false) => {
    if (vehicleChanged) {
      setApplication({
        ...application,
        ...values,
      });
    } else {
      let updateApplicationObj = getApplicationUpdateObject(application);

      updateApplicationObj = {
        ...updateApplicationObj,
        ...values,
      };

      updateApplication(updateApplicationObj)
        .then((res) => {
          setApplication(res);

          if (pxChanged) {
            setQuoteHasChanged(true);
          }
        })
        .catch((err) => {
          dispatch(setPageLoading(false));
          dispatch(
            setErrorMessage(
              err?.message ??
                "An error occurred, please contact support or try again soon"
            )
          );
        });
    }
  };

  const handleVehicleChange = (vehicle) => {
    setVehicleChanged(true);

    setApplication({
      ...application,
      vehicle: {
        ...application?.vehicle,
        ...vehicle,
      },
      deposit: 0,
      annualMileage: 0,
      equity: 0,
      pxDeposite: false,
      term: 0,
    });
    setQuotes([]);
  };

  const handleCloneApplication = (values) => {
    let createApplicationObj = getApplicationCreateObject(
      {
        ...application,
        ...values,
      },
      dealerGuid
    );

    createApplication(createApplicationObj)
      .then((res) => {
        if (res?.quote && Object.keys(res.quote).length > 0) {
          setQuoteHasChanged(false);
          setActiveTab("prequalified");
          navigate(`/customers/${res?.quote.applicationGuid}`);
        } else {
          dispatch(setPageLoading(false));
          dispatch(
            setErrorMessage(
              "An error occurred, please contact support or try again soon"
            )
          );
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(setPageLoading(false));
        dispatch(
          setErrorMessage(
            err?.response?.data?.error ??
              "An error occurred, please contact support or try again soon"
          )
        );
      });
  };

  if (!application || guid !== application.applicationGuid) {
    return null;
  }

  return (
    <div className="application-info-page">
      <Row className="d-none d-md-flex mt-5 application-info-header">
        <Col md={3}>
          Customer:{" "}
          <span>
            {application?.client?.firstName} {application?.client?.lastName}
          </span>
        </Col>
        <Col md={3}>
          Telephone: <span>{application?.client?.clientCellNumber}</span>
        </Col>
        <Col md={3}>
          Status: <span>{application?.submissionStatus}</span>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12} lg={8} style={{ position: "relative" }}>
          <ApplicationInformationForm
            application={application}
            schema={applicationSchema}
            onUpdate={handleApplicationUpdate}
            setApplication={setApplication}
            quotes={quotes}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            quoteChanged={quoteHasChanged}
            setQuoteChanged={setQuoteHasChanged}
            sortBy={quoteSortBy}
            onSortBy={handleQuoteSort}
            dealerQuestions={dealerQuestions}
            onVehicleChange={handleVehicleChange}
          />

          {quoteHasChanged && (
            <div className="quote-change-overlay">
              <div className="quote-changed-content">
                You have changed the vehicle, please populate the quote builder
                and click on Recalculate quote to view your updated quotes
              </div>
            </div>
          )}
        </Col>
        <Col lg={4}>
          <QuoteBuilder
            application={application}
            setApplication={setApplication}
            setQuotes={setQuotes}
            setActiveTab={setActiveTab}
            onQuoteChange={(value) => setQuoteHasChanged(value)}
            vehicleChanged={vehicleChanged}
            onClone={handleCloneApplication}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ApplicationInfoPage;
