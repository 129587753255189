import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";

import { formatCurrency } from "helpers/currency";
import { sendApplications } from "services";
import DealerCommissionModal from "components/modals/DealerCommissionModal";
import HPModal from "components/modals/HPModal";
import LenderLogo from "components/LenderLogo";
import PCPModal from "components/modals/PCPModal";
import SendToCustomer from "components/modals/quote/SendToCustomer";
import SubmitToLenderModal from "components/modals/SubmitToLenderModal";

import { ReactComponent as InfoIcon } from "assets/images/info.svg";

const PreQualifiedQuotes = (props) => {
  const [sendLoading, setSendLoading] = useState(false);
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [showCommissionModal, setShowCommissionModal] = useState(null);
  const [showSubmitToLenderModal, setShowSubmitToLenderModal] = useState(false);
  const [showHPModal, setShowHPModal] = useState(false);
  const [showLender, setShowLender] = useState(null);
  const [showPCPModal, setShowPCPModal] = useState(false);
  const [showQuote, setShowQuote] = useState(null);
  const [showSendToCustomerModal, setShowSendToCustomerModal] = useState(false);
  const [isCheckout, setIsCheckout] = useState(false);

  const hasQuotes = props?.quotes?.lenderList?.some(
    (lender) => lender.quoteList?.length > 0
  );

  const toggleCheckbox = (q, lender) => {
    let localQuotes = [...selectedQuotes];
    if (localQuotes.map((quote) => quote.quoteGuid).includes(q.quoteGuid)) {
      localQuotes = localQuotes.filter(
        (currentQuote) => q.quoteGuid !== currentQuote.quoteGuid
      );
    } else {
      q.lender = {
        lenderName: lender.lenderName,
        lenderLogo: lender.lenderLogo,
      };
      localQuotes.push(q);
    }

    setSelectedQuotes(localQuotes);
  };

  const onProductClick = (q) => {
    if (q.productType === "HP") {
      setShowHPModal(true);
    } else if (q.productType === "PCP") {
      setShowPCPModal(true);
    }
  };

  const onViewQuote = (quote = null, lender = null) => {
    setShowSubmitToLenderModal(quote !== null);
    setShowQuote(quote);
    setShowLender(lender);
  };

  const canShowSubmitToLender = (quote) => {
    if (
      props.application?.quoteStatus === "Approved" ||
      props.application?.applicationStatus === "ProposalSubmitted"
    ) {
      return false;
    }

    if (quote.status === "Declined") {
      return false;
    }

    return true;
  };

  const handleShowSendModal = () => {
    setShowSendToCustomerModal(true);
  };

  const handleSendQuotes = (email) => {
    setSendLoading(true);
    sendApplications({
      quotes: selectedQuotes,
      application: props.application?.applicationGuid,
      email: email,
    })
      .then((res) => {
        setShowSendToCustomerModal(false);
      })
      .finally(() => {
        setSendLoading(false);
      });
  };

  return (
    <div>
      {(props?.quotes?.lenderList?.length === 0 || !hasQuotes) && (
        <div className="no-quotes-message">
          Sorry, we are unable to pre-qualify your customer against any of your
          lenders
        </div>
      )}

      {props?.quotes?.lenderList?.map((lender) => (
        <React.Fragment key={lender?.key ?? lender.lenderName}>
          {lender.quoteList.map((quote) => (
            <Row className="quotes-table" key={quote.quoteGuid}>
              <Col
                xs={2}
                md="hidden"
                className="align-content-center align-items-start align-items-md-center d-md-none"
              >
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    onChange={(e) => toggleCheckbox(quote, lender)}
                    checked={selectedQuotes
                      .map((quote) => quote.quoteGuid)
                      .includes(quote.quoteGuid)}
                  />
                  <span>
                    <span />
                  </span>
                </label>
              </Col>
              <Col
                xs={8}
                md={3}
                className="xs-order-1 align-items-md-center position-relative"
              >
                <div className="d-none d-md-block position-absolute start-0 top-0">
                  <label className="custom-checkbox mb-0">
                    <input
                      type="checkbox"
                      onChange={(e) => toggleCheckbox(quote, lender)}
                      checked={selectedQuotes
                        .map((quote) => quote.quoteGuid)
                        .includes(quote.quoteGuid)}
                    />
                    <span>
                      <span />
                    </span>
                  </label>
                </div>
                <LenderLogo image={lender.lenderLogo} />
              </Col>
              <Col xs={6} md={3} className="xs-order-2">
                <label>
                  Finance Type{" "}
                  <InfoIcon
                    width={13}
                    height={13}
                    onClick={(e) => onProductClick(quote)}
                    className="info-tooltip"
                  />
                </label>
                {quote.productTypeDesc}
              </Col>
              <Col xs={6} md={3} className="xs-order-3">
                <label>
                  APR
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        This APR represents the interest rate applied to your
                        finance agreement
                      </Tooltip>
                    }
                  >
                    <InfoIcon width={13} height={13} className="info-tooltip" />
                  </OverlayTrigger>
                </label>
                {quote.apr}%
              </Col>
              <Col xs={6} md={3} className="xs-order-6">
                <label>Final payment</label>£
                {formatCurrency(quote.residualValue)}
              </Col>
              <Col xs={6} md={3} className="xs-order-7">
                <label>Total interest cost</label>£
                {formatCurrency(quote.totalInterest)}
              </Col>
              <Col xs={6} md={3} className="xs-order-5">
                <label>Total Payable</label>£
                {formatCurrency(quote.totalPayable)}
              </Col>
              <Col xs={6} md={3} className="xs-order-4">
                <label>Monthly amount</label>£{formatCurrency(quote.payments)}
              </Col>
              <Col xs={6} md={3} className="xs-order-8">
                <label>Dealer Commission</label>£
                {formatCurrency(quote.dealerCommission)}
                <a
                  href="#"
                  onClick={(e) => setShowCommissionModal({ quote, lender })}
                  className="commission-info-link"
                >
                  Learn how this is calculated
                </a>
              </Col>
              <Col xs={12} md={12} className="quotes-table-buttons xs-order-8">
                {[
                  "Accepted",
                  "Approved",
                  "Declined",
                  "Referred",
                  "Pending",
                  "Submitted To Lender",
                ].includes(quote?.status) ? (
                  <button
                    onClick={(e) => onViewQuote(quote, lender)}
                    className="green small"
                  >
                    View Decision
                  </button>
                ) : (
                  <>
                    <button
                      onClick={(e) => {
                        setIsCheckout(false);
                        onViewQuote(quote, lender);
                      }}
                      className="primary small"
                      disabled={props.quoteChanged}
                    >
                      View full quote
                    </button>
                    {canShowSubmitToLender(quote) && (
                      <button
                        onClick={(e) => {
                          setIsCheckout(true);
                          onViewQuote(quote, lender);
                        }}
                        className="green small"
                        disabled={props.quoteChanged}
                      >
                        Submit to lender
                      </button>
                    )}
                  </>
                )}
              </Col>
            </Row>
          ))}
        </React.Fragment>
      ))}

      {hasQuotes && (
        <Row>
          <Col xs={12}>
            <div
              className="d-flex justify-content-end align-items-center"
              style={{ gap: 20 }}
            >
              <div className="send-quote-message">
                Please select which quote(s) above to send to customer
              </div>
              <button
                onClick={handleShowSendModal}
                disabled={selectedQuotes.length === 0}
                className="green"
              >
                Send to customer
              </button>
            </div>
          </Col>
        </Row>
      )}

      <HPModal show={showHPModal} onHide={(e) => setShowHPModal(false)} />
      <PCPModal show={showPCPModal} onHide={(e) => setShowPCPModal(false)} />

      <SubmitToLenderModal
        show={showSubmitToLenderModal}
        quote={showQuote}
        allQuotes={props?.quotes ?? []}
        lender={showLender}
        application={props.application}
        onHide={(e) => onViewQuote()}
        isCheckout={isCheckout}
      />

      <SendToCustomer
        show={showSendToCustomerModal}
        quotes={selectedQuotes}
        application={props.application}
        onHide={(e) => setShowSendToCustomerModal(false)}
        onSuccess={handleSendQuotes}
        loading={sendLoading}
      />

      <DealerCommissionModal
        show={showCommissionModal !== null}
        onHide={(e) => setShowCommissionModal(null)}
        quote={showCommissionModal?.quote}
        lender={showCommissionModal?.lender}
      />
    </div>
  );
};

export default PreQualifiedQuotes;
