import React, { useEffect, useState } from "react";
import moment from "moment";
import { FormCheck, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useIdleTimer } from "react-idle-timer";

import { extendToken, getMetaData, getNotificationsCount } from "services";
import { setMetaData } from "store/actions/lenders";
import { loginSuccessful, logout, setNotifications } from "store/actions/auth";
import ExpiryModal from "components/modals/ExpiryModal";
import Footer from "./navigation/Footer";
import GenericErrorModal from "components/modals/GenericErrorModal";
import Header from "./navigation/Header";
import IdleTimer from "components/IdleTimer";
import Loader from "components/Loader";
import MobileBottomMenu from "./navigation/MobileBottomMenu";
import Sidebar from "./navigation/Sidebar";

const UserLayout = (props) => {
  const [showExpiryModal, setShowExpiryModal] = useState(null);
  const [showIdleTimer, setShowIdleTimer] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showGenericErrorModal, setShowGenericErrorModal] = useState(false);

  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const errorMessage = useSelector((state) => state?.auth?.errorMessage);
  const isLoading = useSelector((state) => state?.auth?.loading);
  const loadingMsg = useSelector((state) => state?.auth?.loadingMsg);
  const retailer = useSelector((state) => state?.auth?.dealerGuid);
  const tokenExpiry = useSelector((state) => state?.auth?.expiry);
  const tokenExpiryMoment = moment(tokenExpiry);

  const dispatch = useDispatch();

  let activeInterval;
  let idleInterval;

  useEffect(() => {
    if (errorMessage) {
      setShowGenericErrorModal(true);
    }
  }, [errorMessage]);

  const handleExtendSession = () => {
    extendToken().then((res) => {
      clearInterval(showExpiryModal);
      dispatch(loginSuccessful(res));
      setShowExpiryModal(null);

      setShowIdleTimer(false);

      setTimeout(() => {
        setShowIdleTimer(true);
      }, 500);
    });
  };

  const handleEndSession = () => {
    clearInterval(showExpiryModal);
    dispatch(logout());
    setShowExpiryModal(false);
    setShowIdleTimer(false);
  };

  const handleAutoLogout = () => {
    clearInterval(showExpiryModal);
    setShowExpiryModal(false);
    setShowIdleTimer(false);
  };

  const getNotifications = () => {
    if (!isAdmin) {
      getNotificationsCount({ retailer }).then((res) => {
        dispatch(setNotifications(res));
      });
    }
  };

  useEffect(() => {
    getMetaData().then((res) => {
      let metaData = { ...res };

      Object.keys(metaData).map((key) => {
        metaData[key].sort();
      });
      dispatch(setMetaData(metaData));
    });

    getNotifications();

    let interval = setInterval(() => {
      getNotifications();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleSidebarToggle = (e) => {
    e.preventDefault();
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div
      className={`user-layout ${
        sidebarOpen ? "sidebar-open" : "sidebar-closed"
      }`}
    >
      {isLoading && (
        <div className="page-overlay">
          <div className="loading-animation">
            <Loader />

            {loadingMsg && <h3 className="loading-message">{loadingMsg}</h3>}
          </div>
        </div>
      )}

      <Sidebar
        toggle={handleSidebarToggle}
        sidebarOpen={sidebarOpen}
        onNotificationsClear={getNotifications}
      />

      <div className="content-container">
        <div className="content">
          <Header onNotificationsClear={getNotifications} />

          <div className="content-inner">{props.children}</div>

          <MobileBottomMenu />

          <Footer />
        </div>
      </div>

      <ExpiryModal
        show={!!showExpiryModal}
        onCancel={handleEndSession}
        onConfirm={handleExtendSession}
      />

      {showIdleTimer && (
        <IdleTimer
          showExpiryModal={showExpiryModal}
          setShowExpiryModal={setShowExpiryModal}
          tokenExpiry={tokenExpiryMoment}
          onAutoLogout={handleAutoLogout}
        />
      )}

      <GenericErrorModal
        show={showGenericErrorModal}
        onHide={() => setShowGenericErrorModal(false)}
        message={errorMessage}
      />
    </div>
  );
};

export default UserLayout;
