import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import * as yup from "yup";

import { getErrorLabels } from "helpers/errors";
import { getLenderQuestions, updateBankingDetails } from "services";
import DealerQuestion from "components/form/DealerQuestion";

const AffordabilityStep = (props) => {
  const [errors, setErrors] = useState({});
  const [lenderQuestions, setLenderQuestions] = useState([]);
  const [lenderQuestionAnswers, setLenderQuestionAnswers] = useState({});
  const [lenderQuestionSchema, setLenderQuestionSchema] = useState({});

  useEffect(() => {
    if (props.questions.length === 0) {
      props.onProceed({
        replacementLoan: false,
      });
    } else {
      setLenderQuestions(props.questions);

      let questionSchema = {};
      props.questions.map((q) => {
        questionSchema[q.questionGuid] = yup.string().required();
      });

      setLenderQuestionSchema(yup.object(questionSchema));
    }
  }, [props.questions]);

  const isDisabled = () => {
    return false;
  };

  const handleLenderQuestionsChange = (e) => {
    setLenderQuestionAnswers({
      ...lenderQuestionAnswers,
      [e.target.name]: e.target.value,
    });
  };

  const formatLenderQuestions = () => {
    let lenderQs = [];
    Object.keys(lenderQuestionAnswers).map((key) => {
      lenderQs.push({
        questionGuid: key,
        answerValue: lenderQuestionAnswers[key],
      });
    });

    return lenderQs;
  };

  const handleProceed = () => {
    lenderQuestionSchema
      .validate(lenderQuestionAnswers, { abortEarly: false })
      .then(() => {
        let formattedLenderQuestions = {};

        if (lenderQuestions.length > 0) {
          formattedLenderQuestions = {
            lenderQuestions: formatLenderQuestions(),
          };
        }

        props.onProceed({
          replacementLoan: false,
          ...formattedLenderQuestions,
        });
      })
      .catch((err) => {
        setErrors(getErrorLabels(err));
      });
  };

  return (
    <>
      <Modal.Header closeButton>Submit application to lender</Modal.Header>

      <Modal.Body className="submit-to-lender">
        <p className="info">
          We need more information from the customer to submit this quote to the
          lender.
        </p>

        {lenderQuestions.length > 0 && (
          <>
            {lenderQuestions.map((question) => (
              <Row key={question.questionGuid} className="mb-3">
                <Col xs={12}>
                  <DealerQuestion
                    question={question}
                    answers={lenderQuestionAnswers}
                    setAnswers={setLenderQuestionAnswers}
                    errors={errors}
                  />
                </Col>
              </Row>
            ))}
          </>
        )}

        <p className="info">
          By submitting the application for finance the customer agrees and
          consents to the lender conducting a hard credit search on their
          details.
        </p>
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <button onClick={props.onBack} className="outline">
          Go back
        </button>
        <button
          onClick={handleProceed}
          disabled={isDisabled()}
          className="dark"
        >
          Next - compliance questions
        </button>
      </Modal.Footer>
    </>
  );
};

export default AffordabilityStep;
