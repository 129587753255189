import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import moment from "moment";

import {
  getLenderQuestions,
  selectQuote,
  submitQuote,
  updateBankingDetails,
} from "services";
import AffordabilityStep from "components/modals/quote/AffordabilityStep";
import ApplicationDeclined from "components/modals/quote/outcome/ApplicationDeclined";
import ApplicationPending from "components/modals/quote/outcome/ApplicationPending";
import ApplicationReferred from "components/modals/quote/outcome/ApplicationReferred";
import ApplicationSuccess from "components/modals/quote/outcome/ApplicationSuccess";
import CommissionStep from "components/modals/quote/CommissionStep";
import ComplianceSteps from "components/modals/quote/ComplianceSteps";
import ConsumerSupport from "components/modals/quote/ConsumerSupport";
import EmploymentStep from "components/modals/quote/EmploymentStep";
import FullQuoteStep from "components/modals/quote/FullQuoteStep";
import Loader from "components/Loader";

const SubmitToLenderModal = (props) => {
  const [affordabilityQuestions, setAffordabilityQuestions] = useState([]);
  const [applicationData, setApplicationData] = useState({});
  const [complianceQuestions, setComplianceQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [step, setStep] = useState("quote");
  const [isInCheckout, setIsInCheckout] = useState(false);

  useEffect(() => {
    if (props.show) {
      if (props.quote?.status === "Submitted To Lender") {
        setStep("pending");
      } else if (
        ["Approved", "Accepted", "Referred", "Declined", "Pending"].includes(
          props?.quote?.status
        )
      ) {
        setStep(props?.quote?.status?.toLowerCase());
      } else {
        if (props.isCheckout) {
          setStep("employment");
        } else {
          setStep("quote");
        }

        selectQuote({ quoteGuid: props?.quote?.quoteGuid }).then((res) => {
          setSelectedQuote(res?.quote);
          setComplianceQuestions(res?.questions);

          getLenderQuestions(props.quote.quoteGuid).then((res) => {
            setAffordabilityQuestions(res);
          });
        });
      }
    }
  }, [props.show, props.quote]);

  const handleOnProceed = (values = {}, nextStep = null) => {
    let localApplicationData = {
      ...applicationData,
      ...values,
    };

    setApplicationData(localApplicationData);

    if (nextStep) {
      setStep(nextStep);
    } else {
      setLoading(true);
      updateBankingDetails(localApplicationData).then((res) => {
        submitQuote({
          quoteGuid: props.quote.quoteGuid,
          creditSearchDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        })
          .then((res) => {
            if (res?.applicationStatus == "ProposalSubmitted") {
              setStep("pending");
            } else if (res?.applicationStatus === "LenderDecicionRecived") {
              setStep(res?.quote?.status?.toLowerCase());
            }
          })
          .finally(() => setLoading(false));
      });
    }
  };

  const handleOnHide = () => {
    if (isInCheckout === false) {
      props.onHide();
    } else {
      setStep("employment");
      setIsInCheckout(false);
    }
  };

  if (!props.show) {
    return null;
  }

  return (
    <Modal
      show={props.show}
      onHide={handleOnHide}
      centered
      className={`blurred ${step !== "quote" ? "large" : ""}`}
    >
      {loading ? (
        <>
          <Modal.Header closeButton>
            Submitting application to lender...
          </Modal.Header>

          <Modal.Body className="submit-to-lender d-flex align-items-center justify-content-center flex-column">
            <Loader />

            <p className="info mt-4">
              We are submitting your application. Please wait...
            </p>
          </Modal.Body>
        </>
      ) : (
        <>
          {step === "quote" ? (
            <FullQuoteStep
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={handleOnHide}
              onProceed={(values) => handleOnProceed(values, "employment")}
            />
          ) : step === "employment" ? (
            <EmploymentStep
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              selectedQuote={selectedQuote}
              onBack={(e) => {
                if (props.isCheckout) {
                  handleOnHide();
                } else {
                  setStep("quote");
                }
              }}
              onFullQuote={() => {
                setStep("quote");
                setIsInCheckout(true);
              }}
              onProceed={(values) =>
                handleOnProceed(
                  values,
                  affordabilityQuestions.length > 0
                    ? "affordability"
                    : "compliance"
                )
              }
              setComplianceQuestions={setComplianceQuestions}
            />
          ) : step === "affordability" ? (
            <AffordabilityStep
              application={props.application}
              questions={affordabilityQuestions}
              lender={props.lender}
              quote={props.quote}
              onBack={(e) => setStep("employment")}
              onProceed={(values) => handleOnProceed(values, "compliance")}
            />
          ) : step === "consumerSupport" ? (
            <ConsumerSupport
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={props.onHide}
              onProceed={() => handleOnProceed({}, "compliance")}
            />
          ) : step === "compliance" ? (
            <ComplianceSteps
              questions={complianceQuestions}
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={(e) => setStep("employment")}
              onProceed={() => handleOnProceed({}, "commission")}
            />
          ) : step === "commission" ? (
            <CommissionStep
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={props.onHide}
              onProceed={() => handleOnProceed({})}
            />
          ) : step === "accepted" || step === "approved" ? (
            <ApplicationSuccess
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={props.onHide}
            />
          ) : step === "pending" ? (
            <ApplicationPending
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={props.onHide}
              setStep={setStep}
            />
          ) : step === "referred" ? (
            <ApplicationReferred
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={props.onHide}
            />
          ) : step === "declined" ? (
            <ApplicationDeclined
              application={props.application}
              lender={props.lender}
              quote={props.quote}
              onBack={props.onHide}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </Modal>
  );
};

export default SubmitToLenderModal;
