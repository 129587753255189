import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { getOtherClientApplications } from "services";
import LenderDecision from "components/LenderDecision";

const SubmittedApplications = (props) => {
  const navigate = useNavigate();

  const [applications, setApplications] = useState([]);

  useEffect(() => {
    getOtherClientApplications({
      application: props.application.applicationGuid,
      dealer: props.application?.dealerGuid,
      customer: props.application?.client?.clientEmail,
    }).then((res) => {
      if (Object.keys(res).length > 0) {
        setApplications(res);
      } else {
        setApplications([]);
      }
    });
  }, []);

  const showApplicationPage = (row) => {
    navigate(`/customers/${row.applicationGuid}`);
  };

  return (
    <Table
      striped
      bordered
      hover
      size="sm"
      responsive
      className="customer-activity-table"
    >
      <thead>
        <tr>
          <th>Date & time created</th>
          <th>Lender Decision</th>
          <th>Vehicle Reg</th>
          <th>Amount</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {applications.length === 0 && (
          <tr>
            <td colspan="5" className="py-2">
              No related applications found
            </td>
          </tr>
        )}

        {applications.map((application) => (
          <tr key={application.applicationGuid}>
            <td>{moment(application.createDate).format("DD-MMM-YY HH:mm")}</td>
            <td>
              <LenderDecision
                status={application?.quoteStatus || "Pending"}
                rag={application?.approvedRagStatus}
              />
            </td>
            <td className="table-cell">
              <div className="vehicle-reg">
                {application?.vehicle?.registrationNumber}
              </div>
            </td>
            <td>
              £{application?.vehicle?.salesValue?.toLocaleString("en-GB")}
            </td>
            <td>
              <button
                onClick={(e) => showApplicationPage(application)}
                className="green"
              >
                View
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SubmittedApplications;
