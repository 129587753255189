import React, { useEffect, useState } from "react";
import { Form, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

import {
  archiveApplication,
  getApplicationsByLender,
  getApplicationsList,
  getNotificationApplications,
  searchApplications,
  updateCustomerStatus,
} from "services";
import { setLender } from "store/actions/lenders";
import { setPageLoading, setPageLoadingMsg } from "store/actions/auth";
import ApplicationFilter from "components/ApplicationFilter";
import ApplicationStatus from "components/ApplicationStatus";
import ConfirmModal from "components/modals/ConfirmModal";
import CustomerStatus from "components/CustomerStatus";
import LenderDecision from "components/LenderDecision";
import LenderLogo from "components/LenderLogo";
import Loader from "components/Loader";
import PageSwitcher from "components/PageSwitcher";
import Pill from "components/Pill";
import ResponsiveTable from "components/table/ResponsiveTable";

const LenderListPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [applications, setApplications] = useState([]);
  const [filter, setFilter] = useState(
    searchParams.get("status") ?? "Prevalidated"
  );
  const [page, setPage] = useState(1);
  const [showConfirmModal, setShowConfirmModal] = useState(null);
  const [totalPages, setTotalPages] = useState(1);

  const lender = useSelector((state) => state.lender.lender);
  const frequency = useSelector((state) => state?.lender?.frequency);
  const dealerGuid = useSelector((state) => state?.auth?.dealerGuid);

  const lenderGuid = searchParams.get("lender");
  const notificationType = searchParams.get("type");
  const search = searchParams.get("search");

  useEffect(() => {
    getApplications();
  }, [filter, page, notificationType, search]);

  useEffect(() => {
    if (page === 1) {
      getApplications();
    } else {
      setPage(1);
    }
  }, [frequency]);

  const getApplications = () => {
    dispatch(setPageLoading(true));
    dispatch(setPageLoadingMsg("Loading applications. Please wait..."));

    if (lenderGuid) {
      getApplicationsByLender({ lenderGuid, frequency, page }).then((res) => {
        setApplications(res);
        setTotalPages(res.totalPages);
        dispatch(setPageLoading(false));
      });
    } else if (notificationType) {
      dispatch(setLender(null));
      getNotificationApplications({
        applicationFilter: notificationType,
        page: 1,
        retailer: dealerGuid,
      }).then((res) => {
        setApplications(res);
        setTotalPages(res.totalPages);
        dispatch(setPageLoading(false));
      });
    } else if (search) {
      dispatch(setLender(null));
      searchApplications({ searchCriteria: search, page }).then((res) => {
        setApplications(res);
        setTotalPages(res.totalPages);
        dispatch(setPageLoading(false));
      });
    } else {
      dispatch(setLender(null));
      getApplicationsList({ frequency, page, applicationFilter: filter }).then(
        (res) => {
          setApplications(res);
          setTotalPages(res.totalPages);
          dispatch(setPageLoading(false));
        }
      );
    }
  };

  const showApplicationPage = (row) => {
    navigate(`/customers/${row.applicationGuid}`);
  };

  const handleArchiveApplication = (row) => {
    archiveApplication(row.applicationGuid).then((res) => {
      setShowConfirmModal(null);
      getApplications();
    });
  };

  const handleStatusChange = (e, row) => {
    let customerStatus = e.target.value;
    let updatedCustomerStatus = customerStatus.replace(/ /g, "");

    updateCustomerStatus({
      applicationGuid: row.applicationGuid,
      customerStatus: updatedCustomerStatus,
    }).then((res) => {
      let localApplicationList = [...applications.applicationList];
      let updatedApplicationList = localApplicationList.map((app) => {
        if (app.applicationGuid === row.applicationGuid) {
          app.customerStatus = customerStatus;
        }
        return app;
      });

      setApplications({
        ...applications,
        applicationList: updatedApplicationList,
      });
    });
  };

  const getPagesToShow = () => {
    let pages = [];

    if (totalPages < 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (page < 4) {
        pages = [1, 2, 3, 4, 5];
      } else if (page + 2 < totalPages) {
        pages = [page - 2, page - 1, page, page + 1, page + 2];
      } else {
        pages = [
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ];
      }
    }

    return pages;
  };

  return (
    <div className="applications-list-page">
      <div className="page-top">
        <div className="page-top-title-logo">
          <h3 className="page-title">Customers</h3>

          {lender && lenderGuid && <LenderLogo image={lender.lenderLogo} />}
        </div>

        <div className="page-top-actions">
          {search === null && (
            <>
              <PageSwitcher
                options={[
                  {
                    title: "Today",
                    value: "Day",
                  },
                  {
                    title: "Week",
                    value: "Week",
                  },
                  {
                    title: "Month",
                    value: "Month",
                  },
                  {
                    title: "Year",
                    value: "Year",
                  },
                ]}
                selected={frequency}
              />

              <ApplicationFilter
                value={filter}
                onChange={(val) => setFilter(val)}
              />
            </>
          )}
        </div>
      </div>

      <div className="mt-4">
        <ResponsiveTable
          type="bordered"
          data={applications.applicationList ?? []}
          columns={[
            {
              title: "Date & time created",
              className: "small",
              render: (row) => (
                <>{moment(row.createDate).format("DD-MMM-YY HH:mm")}</>
              ),
              order: 6,
              mobileWidth: 8,
              smallWidth: 8,
            },
            {
              title: "Mobile Application Status",
              headerClassName: "d-block d-md-none combined-field",
              className: "small d-block d-md-none combined-field",
              render: (row) => (
                <>
                  {row?.quoteStatus ? (
                    <LenderDecision
                      status={row?.quoteStatus || "Pending"}
                      rag={row?.approvedRagStatus}
                    />
                  ) : (
                    <ApplicationStatus status={row?.submissionStatus} />
                  )}
                </>
              ),
              showMobileHeader: false,
              order: 2,
            },
            {
              title: "Name",
              className: "small title",
              render: (row) => (
                <>
                  {row?.client?.firstName} {row?.client?.lastName}
                </>
              ),
              showMobileHeader: false,
              smallWidth: 6,
              order: 1,
            },
            {
              title: "Make",
              headerClassName: "desktop-cell",
              className: "small desktop-cell",
              render: (row) => <>{row?.vehicle?.make}</>,
              order: 3,
              mobileWidth: 4,
              smallWidth: 4,
            },
            {
              title: "Model",
              headerClassName: "desktop-cell",
              className: "small desktop-cell",
              render: (row) => <>{row?.vehicle?.model}</>,
              order: 4,
              mobileWidth: 4,
              smallWidth: 4,
            },
            {
              title: "Vehicle Reg",
              className: "small",
              render: (row) => (
                <div className="vehicle-reg">
                  {row?.vehicle?.registrationNumber}
                </div>
              ),
              order: 5,
              mobileWidth: 4,
              smallWidth: 4,
            },
            {
              title: "Status",
              className: "small d-none d-md-flex",
              render: (row) => (
                <>
                  <ApplicationStatus status={row?.submissionStatus} />
                </>
              ),
            },
            {
              title: "Lender Decision",
              className: "small d-none d-md-flex",
              render: (row) => (
                <>
                  <LenderDecision
                    status={row?.quoteStatus ?? "N/A"}
                    rag={row?.approvedRagStatus}
                  />
                </>
              ),
              showMobileHeader: false,
            },
            {
              title: "Customer Status",
              className: "small d-none d-md-flex",
              render: (row) => (
                <>
                  {row.quoteStatus === "Approved" && !row?.customerStatus ? (
                    <Form.Select
                      value={row?.customerStatus}
                      onChange={(e) => handleStatusChange(e, row)}
                    >
                      <option value={null}></option>
                      <option value="Completed">Deal Completed</option>
                      <option value="Not Taken Up">Not Taken Up</option>
                    </Form.Select>
                  ) : (
                    <CustomerStatus status={row?.customerStatus ?? "N/A"} />
                  )}
                </>
              ),
            },
            {
              title: "Channel",
              className: "small d-none d-md-flex",
              render: (row) => <>{row?.enquirySource ?? "N/A"}</>,
            },
            {
              title: "Actions",
              className: "small actions",
              headerClassName: "actions",
              render: (row) => (
                <>
                  <button
                    onClick={(e) => showApplicationPage(row)}
                    className="green"
                  >
                    View
                  </button>
                  <button
                    onClick={(e) => setShowConfirmModal(row)}
                    className="grey d-none d-md-block"
                  >
                    Archive
                  </button>
                </>
              ),
              order: 7,
              showMobileHeader: false,
              mobileWidth: 4,
              smallWidth: 4,
            },
          ]}
          className="customers-table"
        />

        <Pagination className="table-pagination">
          <Pagination.First disabled={page === 1} onClick={(e) => setPage(1)} />

          <Pagination.Prev
            disabled={page === 1}
            onClick={(e) => setPage(page - 1)}
          />

          {getPagesToShow().map((p) => (
            <Pagination.Item
              active={p === page}
              onClick={(e) => setPage(p)}
              key={p}
            >
              {p}
            </Pagination.Item>
          ))}

          <Pagination.Next
            disabled={page === totalPages}
            onClick={(e) => setPage(page + 1)}
          />

          <Pagination.Last
            disabled={page === totalPages}
            onClick={(e) => setPage(totalPages)}
          />
        </Pagination>

        <ConfirmModal
          show={showConfirmModal !== null}
          text="Are you sure you wish to archive this application?"
          onCancel={(e) => setShowConfirmModal(null)}
          onConfirm={(e) => handleArchiveApplication(showConfirmModal)}
        />
      </div>
    </div>
  );
};

export default LenderListPage;
