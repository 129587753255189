import React from "react";
import { Modal } from "react-bootstrap";

const GenericErrorModal = (props) => {
  const getFieldLabel = (field) => {
    return field.replace(/\./, " - ").replace(/([A-Z])/g, " $1");
  };

  return (
    <>
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        className="product-info-modal blurred"
      >
        <Modal.Header closeButton>An error occurred</Modal.Header>
        <Modal.Body>
          <p>
            {props.message ??
              "An error occurred, please contact support or try again soon"}
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GenericErrorModal;
